import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/public' },
  { path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
  { path: 'staff',  loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule) }
];

/**
 *
 */

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
