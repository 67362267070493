import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../services/auth.service";
import {StoreTableDto} from "../model/product.model";
import {GuesttrackingService} from "../services/guesttracking.service";

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  userName: string = ""
  storeName: string = ""


  constructor(private router: Router, private authService: AuthService, public guestTracking : GuesttrackingService) {
  }

  ngOnInit(): void {
    if (!this.authService.isAuthenticated()) {
      this.router.navigateByUrl("staff/login");
    }

    this.authService.getLoggedIn().subscribe({
      next: res => {
        this.userName = res.firstname
      }
    });

    this.authService.getLoggedInStore().subscribe({
      next: res => {
        this.storeName = res.displayName
      }
    })

    this.guestTracking.loadStaff()
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl("staff/login");
  }

  openGuestCashin() {
    this.router.navigateByUrl("staff/guest-cashin");
  }
}
