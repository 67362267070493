<div>

  <div *ngFor="let cartItem of cart.cartItems" class="card">


    <mat-grid-list cols=6 rowHeight="2:1">

      <mat-grid-tile colspan="5"></mat-grid-tile>
      <mat-grid-tile>
        <span><mat-icon style="font-size: 1.4rem" (click)="remove(cartItem)">close</mat-icon></span>
      </mat-grid-tile>

      <mat-grid-tile colspan="6">
          <span style="font-size: 0.9rem">{{ cartItem.quantity }} x {{ getProductName(cartItem) }}</span>
      </mat-grid-tile>
      <mat-grid-tile colspan="6">
        {{getProductAmount(cartItem)}} {{'enum.unit.' + getProductUnit(cartItem) | translate}}
      </mat-grid-tile>

      <mat-grid-tile colspan="6" *ngIf="cartItem.option">
        {{cartItem.option}}
      </mat-grid-tile>

      <mat-grid-tile colspan="4">
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <span style="font-size: 1.2rem">€ {{getProductPrice(cartItem) | number: '1.2-2':'de'}}</span>
      </mat-grid-tile>

    </mat-grid-list>


  </div>

  <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile colspan="1"></mat-grid-tile>
    <mat-grid-tile colspan="2" *ngIf="cart.cartItems && cart.cartItems.length > 0">
      <span style="width:100%; align-self:center; font-size: 1rem">Summe: € {{ cart.sum | number: '1.2-2':'de'}}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="3" *ngIf="cart.cartItems && cart.cartItems.length == 0">
      <span style="font-size: 1rem">Keine Produkte zur Bestellung ausgewählt</span>
    </mat-grid-tile>

    <mat-grid-tile colspan="3" *ngIf="cart.cartItems && cart.cartItems.length > 0">
      <button mat-fab extended style="background: transparent; margin: 1.5rem" [disabled]="orderInProcess" (click)="order()"><mat-icon>shopping_cart</mat-icon>Bestellen</button>
    </mat-grid-tile>

  </mat-grid-list>



</div>
