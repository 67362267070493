import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {KiUserDto} from "../model/kiuser/kiuser.model";


@Injectable({
  providedIn: 'root'
})
export class KiUserApiService {

  constructor(private http: HttpClient) { }

  loadLoggedIn() :Observable<KiUserDto>{
    return this.http.get<KiUserDto>(environment.baseurl + "v1/kiuser/profile")
  }


}
