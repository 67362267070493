import {Injectable, OnInit} from '@angular/core';
import {
  PlaceStaffOrderDto,
  ShoppingCartDto,
  ShoppingCartProductItemDto,
  StaffOrderInputDto,
  UpdateShoppingCartInputDto
} from "../model/shoppingcart.model";
import {Observable, Subject} from "rxjs";
import {ShoppingCartApiService} from "../api/shoppingcart-api.service";
import {CategoryWithProductItemsDto, ProductDto, ProductVariantDto} from "../model/product.model";
import {NotificationService} from "./notification.service";
import {ShopnowOrderDto} from "../model/order.model";
import {OrderApiService} from "../api/order-api.service";
import {StoreGuestApiService} from "../api/storeguest-api.service";

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class GuesttrackingService implements OnInit{

  private _trackingCode : string | null = null

  public guestTrackingChanged = new Subject<boolean>();

  private _guestTrackingEnabled : boolean = false

  constructor(private storeGuestApiService: StoreGuestApiService) {
  }

  ngOnInit(): void {
    let trackingCode = localStorage.getItem('guestTrackingCode');
    if(trackingCode) {
      this._trackingCode = trackingCode;
    }
  }

  public loadPublic(tableCode : string) {
    this.storeGuestApiService.getConfig(tableCode).subscribe({
      next: res => {
        this._guestTrackingEnabled = res.guestTrackingEnabled;
        this.guestTrackingChanged.next(this._guestTrackingEnabled)
      }
    })
  }

  public loadStaff() {
    this.storeGuestApiService.getConfigAsStaff().subscribe({
      next: res => {
        this._guestTrackingEnabled = res.guestTrackingEnabled;
        this.guestTrackingChanged.next(this._guestTrackingEnabled)
      }
    })
  }

  get trackingCode(): string | null {

    if(this._trackingCode == null && localStorage.getItem("guestTrackingCode")) {
      this._trackingCode = localStorage.getItem("guestTrackingCode")
    }

    return this._trackingCode;
  }
  set trackingCode(value: string | null) {
    this._trackingCode = value;
    if(value) {
      localStorage.setItem('guestTrackingCode', value);
    }
    else {
      localStorage.removeItem('guestTrackingCode')
    }
  }


  get guestTrackingEnabled(): boolean {
    return this._guestTrackingEnabled;
  }
}
