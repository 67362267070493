<div>

  <div *ngFor="let order of orders" class="card">

    <p>{{order.orderNumber}} | {{order.createdAt | date : 'dd.MM.yyyy hh:mm' : '' : 'de'}}</p>
    <p style="font-size: 0.8rem" *ngIf="order.guestCode">{{order.guestName}} - {{order.guestCode}}</p>

    <div *ngFor="let orderPosition of order.positions" class="variantcard">

      <mat-grid-list cols="4" rowHeight="2:1">

        <mat-grid-tile colspan="2">
          {{ orderPosition.quantity }} mal {{ getProductName(orderPosition) }}
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          {{getProductAmount(orderPosition)}} {{'enum.unit.' + getProductUnit(orderPosition) | translate}}
        </mat-grid-tile>
        <mat-grid-tile colspan="2" *ngIf="orderPosition.option">
          <span>{{orderPosition.option}}</span>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" *ngIf="!orderPosition.option">
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <span>€ {{orderPosition.positionSum | number: '1.2-2':'de'}}</span>
        </mat-grid-tile>



      </mat-grid-list>

    </div>

    <mat-grid-list cols="3" rowHeight="2:1">
      <mat-grid-tile colspan="2"></mat-grid-tile>
      <mat-grid-tile colspan="1">
        <span style="font-size: 1rem">Summe: € {{ order.sum | number: '1.2-2':'de'}}</span>
      </mat-grid-tile>

    </mat-grid-list>
  </div>


  <mat-grid-list cols="3" rowHeight="2:1" *ngIf="overallSum > 0">
    <mat-grid-tile colspan="2"></mat-grid-tile>
    <mat-grid-tile colspan="1">
      <span style="font-size: 1.2rem">Summe: € {{ overallSum | number: '1.2-2':'de'}}</span>
    </mat-grid-tile>



  </mat-grid-list>


</div>

<div *ngIf="!orders || orders.length == 0"  class="card">

  <p>Es gibt aktuell keine Bestellungen zu diesem Tisch</p>

</div>
