import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {StoreDto} from "../model/store/store.model";



@Injectable({
  providedIn: 'root'
})
export class StoreApiService {

  constructor(private http: HttpClient) { }

  getLoggedInStore() :Observable<StoreDto> {
    return this.http.get<StoreDto>(environment.baseurl + "v1/store/profile")
  }


}
