import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StaffComponent} from "./staff.component";
import {LoginComponent} from "./login/login.component";
import {TableDetailComponent} from "./table-detail/table-detail.component";

import {StaffGuardService as StaffGuard} from "../services/staff-guard.service";
import {SignupComponent} from "./signup/signup.component";
import {TableListComponent} from "./table-list/table-list.component";
import {GuestCashinComponent} from "./guest-cashin/guest-cashin.component";
import {SignupApploginComponent} from "./signup-applogin/signup-applogin.component";


const routes: Routes = [
  { path: '', component: StaffComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup-applogin', component: SignupApploginComponent },
  { path: 'table-list/:sectionId', canActivate: [StaffGuard], component: TableListComponent },
  { path: 'table-detail/:id/:sectionId', canActivate: [StaffGuard], component: TableDetailComponent },
  { path: 'guest-cashin', canActivate: [StaffGuard], component: GuestCashinComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StaffRoutingModule { }
