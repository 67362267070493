import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {ShopnowOrderDto, ShopnowOrderWithPositionsDto} from "../model/order.model";
import {PlaceStaffOrderDto} from "../model/shoppingcart.model";


@Injectable({
  providedIn: 'root'
})
export class OrderApiService {

  constructor(private http: HttpClient) { }

  getOpenOrders() : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.get<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/shopnow/order/open")
  }

  placeStaffOrder(placeStaffOrderDto : PlaceStaffOrderDto) : Observable<ShopnowOrderDto>  {
    return this.http.post<ShopnowOrderDto>(environment.baseurl + "v1/shopnow/order/stafforder", placeStaffOrderDto)
  }

  demandCashin() {
    return this.http.put(environment.baseurl + "v1/shopnow/order/demandcashin", {});
  }

}
