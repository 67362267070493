import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProductDetailSheetComponent} from "../product-detail-sheet/product-detail-sheet.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {ActivatedRoute} from "@angular/router";
import {StorefeedApiService} from "../../api/storefeed-api.service";
import {ProductDto, ProductVariantDto} from "../../model/product.model";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges {

  @Input()
  selectedCategoryId : number | null = null;

  @Output()
  onBack  = new EventEmitter<any>();
  @Output()
  onOpenShoppingCart  = new EventEmitter<any>();

  products : ProductDto[] = []

  constructor(private storefeedApiService: StorefeedApiService, private bottomSheet: MatBottomSheet, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.load()
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.load()
  }

  load() {


    this.storefeedApiService.getProductsOfCategory(localStorage.getItem("tableCode")!, this.selectedCategoryId!, null).subscribe({
      next: res => {
        this.products = res;
      },
      error: err => {

      }
    })
  }


  openProductDetails(product: ProductDto, variant: ProductVariantDto | null) {

    let ref = this.bottomSheet.open(ProductDetailSheetComponent, {
      data: {
        product: product,
        variant: variant
      }
    });

    ref.afterDismissed().subscribe({
      next: res => {
        if(res && res.openShoppingCart) {
          this.onOpenShoppingCart.emit()
        }
      }
    })

  }

  back() {
    this.onBack.emit();
  }
}
