import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ShoppingcartService} from "./services/shoppingcart.service";
import {StoretableApiService} from "./api/storetable-api.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  activeTab = 0;
  tableName: string = ""
  storeName: string = ""

  constructor(private route: ActivatedRoute, public shoppingCartService : ShoppingcartService, private storeTableApiService : StoretableApiService, private translate: TranslateService){
    translate.addLangs(['de']);
    translate.setDefaultLang('de');
    translate.use('de');
  }

  ngOnInit(): void {

   /* this.route.queryParams.subscribe(params => {
      let shopnowId = params['shopnowId'];
      let tableCode = params['tableCode'];
      localStorage.setItem("shopnowid", shopnowId);
      localStorage.setItem("tableCode", tableCode);

      this.storeTableApiService.get(shopnowId, tableCode).subscribe({
        next: res => {
          this.tableName = res.name
          this.storeName = res.storeName
        }
      })

    });

    this.shoppingCartService.load().subscribe() */


  }


  openProducts() {
    this.activeTab = 0;
  }

  openShoppingCart() {
    this.activeTab = 1;
  }

  openOrderOverview() {
    this.activeTab = 2;
  }
}

