<div *ngIf="tableCode">
  <mat-toolbar>
    <span>{{ storeName }} / {{ tableName }}</span>
  </mat-toolbar>

  <div class="menu-tabs">
    <mat-grid-list cols="3" rowHeight="3:1">
      <mat-grid-tile [class]="activeTab == 0 ? 'selected' : ''" (click)="openProducts()">Karte</mat-grid-tile>
      <mat-grid-tile [class]="activeTab == 1 ? 'selected' : ''" (click)="openShoppingCart()">
        <div matBadge="{{shoppingCartService.cart.productCount}}" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false" class="demo-section">Bestellung</div>
      </mat-grid-tile>
      <mat-grid-tile [class]="activeTab == 2 ? 'selected' : ''" (click)="openOrderOverview()">Rechnung</mat-grid-tile>
    </mat-grid-list>
  </div>


  <div>
    <app-shopping-feed *ngIf="activeTab == 0" (onOpenShoppingCart)="openShoppingCart()"></app-shopping-feed>
    <app-shopping-cart *ngIf="activeTab == 1"></app-shopping-cart>
    <app-open-order-overview *ngIf="activeTab == 2"></app-open-order-overview>
  </div>
</div>

<div *ngIf="!tableCode" class="content">

  <h3>Willkommen bei KISTL LIVE</h3>


  <p style="padding: 2rem">Bitte gib hier den Tischcode ein oder scanne den QR-Code an deinem Tisch.</p>

  <form [formGroup]="validateForm" (ngSubmit)="submitTableCode()">
    <p>
      <mat-form-field>
        <input type="text" matInput placeholder="Code" formControlName="tableCode">
      </mat-form-field>
    </p>

    <div class="button">
      <button mat-fab extended type="submit" style="background: transparent; margin-top: 0.2rem; align-self: center">Absenden</button>
    </div>

  </form>

  <div style="margin-top: 2rem">
    <button mat-fab extended type="submit" style="background: transparent; margin-top: 0.2rem; align-self: center" (click)="openStaff()"><mat-icon>person</mat-icon>Service-Login</button>
  </div>


</div>

