import {ProductDto, ProductVariantDto} from "./product.model";

export interface ShopnowOrderDto {
  id: number
  orderNumber: string
  sum: number
  state: ShopnowOrderState
  paidAt: string
  createdAt: string
  updatedAt: string
  effectiveVoucherSum: number
}

export interface ShopnowOrderWithPositionsDto {
  id: number
  orderNumber: string
  sum: number
  state: ShopnowOrderState
  tableName: string
  guestName: string
  guestCode: string
  paidAt: string
  createdAt: string
  updatedAt: string
  positions: ShopnowOrderPositionDto[]
}


export interface ShopnowOrderPositionDto {
  id: number | null
  orderId: string
  productId: string
  variantId: string
  product: ProductDto
  variant: ProductVariantDto
  quantity: number
  priceAmount: number
  positionSum: number
  positionPriceOneUnit: number
  option: string | null
}

export interface StoreInvoiceDto {
  id: number
  storeInvoiceNumber: string
  storeInvoiceURL: string
}

export interface StaffMarkCashinInputDto {
  storeTableId: number
  cashedInOrderPositions: number[]
  paymentMethod: ShopnowPaymentMethod
  sumWithTip: number | null
}

export interface StaffCancelInputDto {
  storeTableId: number
  cancelledOrderPositions: number[]
}

export interface StaffMarkCashinGuestInputDto {
  storeGuestId: number
  cashedInOrderPositions: number[]
  paymentMethod: ShopnowPaymentMethod
  sumWithTip: number | null
}

export interface StaffCancelGuestInputDto {
  storeGuestId: number
  cancelledOrderPositions: number[]
}

export interface ShopnowStaffMoveOrdersInputDto {
  storeTableId: number
  targetStoreTableId: number
  orderPositionIdsToMove: number[]
}

export enum ShopnowOrderState {
  OPEN = "CREATED",
  PRINTED = "PRINTED",
  CASHIN_DEMANDED = "CASHIN_DEMANDED",
  PAID = "PAID"
}

export enum ShopnowPaymentMethod {
  CASH = "CASH",
  CREDITCARD = "CREDITCARD",
  DEBITCARD = "DEBITCARD"
}

export interface ShopnowStoreConfigDto {
  guestTrackingEnabled: boolean
}


export interface StoreGuestDto {
  id: number
  name: string
  code: string
}
