<div>

  <div>
    <mat-grid-list cols=6 rowHeight="1:1">
      <mat-grid-tile colspan="4">
        <span style="font-size: 1rem">{{ cashinMarkedSum | number: '1.2-2':'de'}} € in Abrechnung</span>
      </mat-grid-tile>
      <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
        <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="markCashin()">
          <mat-icon>send</mat-icon>
        </button>
        <button mat-mini-fab extended style="background: transparent; box-shadow: none"
                (click)="openCancelConfirmation()">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field appearance="fill">
          <mat-label>Zahlungsart</mat-label>
          <mat-select [(value)]="selectedPaymentMethod" style="width: 100%">
            <mat-option [value]="ShopnowPaymentMethod.CASH">
              Barzahlung
            </mat-option>
            <mat-option [value]="ShopnowPaymentMethod.DEBITCARD">
              Bankomatkarte
            </mat-option>
            <mat-option [value]="ShopnowPaymentMethod.CREDITCARD">
              Kreditkarte
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <mat-form-field appearance="fill">
          <mat-label>Betrag inkl. Trinkgeld</mat-label>
          <input matInput type="number" placeholder="..." [(ngModel)]="sumWithTip">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div>
    <mat-grid-list cols="10" rowHeight="1:1">
      <mat-grid-tile colspan="10">
        <mat-checkbox (change)="markAll($event)">Alle Positionen</mat-checkbox>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div *ngFor="let order of orders; index as oi" class="card">

    <p>{{ order.orderNumber }} | {{ order.createdAt | date : 'dd.MM.yyyy HH:mm' : '' : 'de' }}</p>
    <p style="font-size: 0.8rem" *ngIf="order.guestCode">{{order.guestName}} - {{order.guestCode}}</p>

    <div *ngFor="let orderPosition of order.positions; index as i" class="variantcard">

      <mat-grid-list cols="10" rowHeight="1:2">

        <mat-grid-tile colspan="1">
          <mat-checkbox [checked]="isChecked(oi, i)" (change)="markCheck($event, orderPosition, oi, i)"></mat-checkbox>
        </mat-grid-tile>
        <mat-grid-tile colspan="6">
          {{ getProductName(orderPosition) }}  {{ getProductAmount(orderPosition) | specialamount }} {{ 'enum.unit.' + getProductUnit(orderPosition) | translate }} {{ orderPosition.option }}
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          <span>€ {{ getProductPrice(orderPosition) | number: '1.2-2':'de' }}</span>
        </mat-grid-tile>


      </mat-grid-list>

    </div>

  </div>

  <div *ngIf="!orders || orders.length == 0" class="card">

    <p>Es gibt aktuell keine Bestellungen zu diesem Tisch</p>

  </div>


</div>
<ng-template #cancelConfirmationDialog>
  <h2 matDialogTitle>Position stornieren</h2>
  <p matDialogContent>Willst du die markierten Positionen wirklich stornieren?</p>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>Abbrechen</button>
    <button mat-button matDialogClose (click)="cancelPositions()">Ja</button>
  </mat-dialog-actions>
</ng-template>
