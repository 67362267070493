<div class="parent" *ngIf="current==0">
  <form [formGroup]="personalForm" (ngSubmit)="submitPersonalForm()">


    <h5>Geben sie hier ihre persönlichen Daten ein</h5>


    <mat-form-field style="width: 100%" required>
      <input style="align-self: center" type="text" matInput placeholder="Vorname*" formControlName="firstname">
    </mat-form-field>

    <mat-form-field style="width: 100%" required>
      <input type="text" matInput placeholder="Nachname*" formControlName="lastname">
    </mat-form-field>


    <mat-form-field style="width: 100%" required>
      <input type="mail" matInput placeholder="E-Mail Adresse*" formControlName="mail">
    </mat-form-field>


    <div>
      <div>
        <mat-form-field style="width: 25%;">
          <mat-select [disabled]="true" formControlName="phonePrefix">
            <mat-option value="+43">+43</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 75%;">
          <input type="phone" matInput placeholder="Mobiltelefonnummer*" formControlName="phone">
        </mat-form-field>
      </div>
    </div>


    <mat-form-field style="width: 100%" required>
      <mat-label>Geburtstag eingeben oder auswählen</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="birthday">
      <mat-hint>dd.MM.yyyy</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>


    <mat-form-field style="width: 100%" required>
      <input type="password" matInput placeholder="Password" formControlName="password">
    </mat-form-field>

    <mat-form-field style="width: 100%" required>
      <input type="password" matInput placeholder="Passwort wiederholen" formControlName="passwordrepeat">
    </mat-form-field>


    <button mat-button style="width: 100%">Fortfahren</button>
  </form>
</div>

<!-- Phone Number Verification-->
<div class="parent" *ngIf="current==1">

  <div style="width: 100%">

    <div style="min-width: 25rem; max-width: 100%">

      <div style="width: 100%;  margin-top: 0.5rem">
        <p>Geben sie hier den 6-stelligen Code ein, den wir an deine Mobiltelefonnummer gesendet haben:</p>
      </div>

      <div style="width: 100%;  margin-top: 0.5rem;">
        <div>
          <code-input
                      (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
      </div>
      <div style="width: 100%;  margin-top: 0.5rem;">
        {{signupService.devToken}}
      </div>
      <div style="width: 100%; margin-top: 0.5rem">
        <div>
          <button mat-button [disabled]="!phoneVerified" (click)="continuePhoneVerification()" style="width: 100%">{{'ui.button.continue.label' | translate}}</button>
        </div>
      </div>
      <div style="width: 100%; margin-top: 0.5rem">
        <div>
          <button mat-button *ngIf="!phoneVerified"  (click)="resendPhoneCode()" style="width: 100%">{{'ui.button.resend.label' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>

