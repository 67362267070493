import {Injectable} from '@angular/core';
import {ShoppingCartDto, ShoppingCartProductItemDto, UpdateShoppingCartInputDto} from "../model/shoppingcart.model";
import {Observable, Subject} from "rxjs";
import {ShoppingCartApiService} from "../api/shoppingcart-api.service";
import {ProductDto, ProductVariantDto} from "../model/product.model";
import {NotificationService} from "./notification.service";
import {ShopnowOrderDto} from "../model/order.model";
import {GuesttrackingService} from "./guesttracking.service";

@Injectable({
  providedIn: 'root'
})
export class ShoppingcartService {


  cart: ShoppingCartDto = {
    cartItems: [],
    productCount: 0,
    sum: 0,
  }


  public shoppingCartChanged = new Subject<number>();

  constructor(private shoppingCartApiService: ShoppingCartApiService, private notificationService : NotificationService, private guestTracking : GuesttrackingService) {
  }

  public load() {
    const observable = new Observable<ShoppingCartDto>(observer => {
      this.shoppingCartApiService.getShoppingCart().subscribe({
        next: res => {
          this.cart = res
          this.notify()
          observer.next(this.cart);
          observer.complete()
        },
        error: error => {
          observer.error(error)
          observer.complete()
        }
      })
    });

    return observable;
  }


  public update(product: ProductDto, variant: ProductVariantDto | null, quantity: number | null, option: string | null, notify: boolean): Observable<any> {

    const observable = new Observable<any>(
      observer => {

        var updateInput: UpdateShoppingCartInputDto = {
          productId: product.id,
          variantId: variant == null ? null : variant.id,
          quantity: quantity == null ? this.getQuantityOfProduct(product, variant, option) : quantity,
          comment: null,
          tableCode: localStorage.getItem("tableCode")!,
          option: option,
          guestTrackingCode: this.guestTracking.trackingCode
        }

        this.shoppingCartApiService.updateShoppingCart(updateInput).subscribe({
          next: res => {
            this.cart = res
            if (notify) {
              this.notify()
            }
            observer.next();
          }, error: err => {
            observer.error(err)
          }
        })
      })

    return observable
  }


  public remove(item: ShoppingCartProductItemDto) {
    this.shoppingCartApiService.removeItem(item.id).subscribe({
      next: res => {
        this.cart = res
        this.cart.cartItems = this.cart.cartItems.filter(ci => ci.quantity > 0);
        this.notify()
      },
      error: err => {
        //this.notficationService.handleRequestError(err)
      }
    })
  }

  public removeProduct(product: ProductDto) {
    for (var item of this.cart.cartItems) {
      if (item.product.id === product.id) {
        this.shoppingCartApiService.removeItem(item.id).subscribe({
          next: res => {
            this.cart = res
            this.cart.cartItems = this.cart.cartItems.filter(ci => ci.quantity > 0);
            this.notify()
          },
          error: err => {
            //this.notficationService.handleRequestError(err)
          }
        })

      }
    }
  }

  public getQuantityOfProduct(product: ProductDto, variant: ProductVariantDto | null, option: string | null): number {
    for (var item of this.cart.cartItems) {
      if(variant == null && item.variant == null && item.option == option && item.product.id == product.id) return item.quantity;
      if (variant != null && item.variant != null && item.variant.id === variant.id && item.option == option) return item.quantity;
    }
    return 0
  }

  public increaseQuantityOfProduct(product: ProductDto) {

    for (var item of this.cart.cartItems) {

      if (item.product.id === product.id) {
        item.quantity++
      }

    }
  }


  public decreaseQuantityOfProduct(product: ProductDto, variant: ProductVariantDto | null, option: string | null) {

    let current = this.getQuantityOfProduct(product, variant, option);
    if (current <= 0) return;

    for (var item of this.cart.cartItems) {

      if (item.product.id === product.id) {
        item.quantity--
      }

    }
  }

  public order(tableCode : string): Observable<ShopnowOrderDto> {

    const observable = new Observable<ShopnowOrderDto>(observer => {
      this.shoppingCartApiService.order(tableCode).subscribe({
        next: res => {
          this.cart = {
            cartItems: [],
            productCount: 0,
            sum: 0
          }
          this.notify();
          observer.next(res);
        },
        error: err => {
          //this.notficationService.handleRequestError(err)
          observer.error(err)
        }
      })
    });

    return observable;
  }


  private notify() {
    this.shoppingCartChanged.next(this.cart.productCount)
  }

}
