import {Component, EventEmitter, Output} from '@angular/core';
import {CategoryDto} from "../../model/product.model";


@Component({
  selector: 'app-shopping-feed',
  templateUrl: './shopping-feed.component.html',
  styleUrls: ['./shopping-feed.component.scss']
})
export class ShoppingFeedComponent {

  selectedCategory : number | null = null;

  @Output()
  onOpenShoppingCart  = new EventEmitter<any>();


  load(category : CategoryDto) {
      this.selectedCategory = category.id;
  }

  back() {
    this.selectedCategory = null;
  }

  openShoppingCart() {
    this.onOpenShoppingCart.emit()
  }
}
