import {Component, Input} from '@angular/core';
import {
  CategoryWithProductItemsDto,
  ProductDto,
  ProductVariantDto,
  StoreSectionDto,
  StoreTableDto
} from "../../model/product.model";
import {StorefeedApiService} from "../../api/storefeed-api.service";
import {StaffOrderService} from "../../services/stafforder.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {OrderDetailSheetComponent} from "../order-detail-sheet/order-detail-sheet.component";
import {OrderItemOptionComponent} from "../order-item-option/order-item-option.component";
import {MatChipListboxChange} from "@angular/material/chips";

@Component({
  selector: 'app-table-order',
  templateUrl: './table-order.component.html',
  styleUrls: ['./table-order.component.scss']
})
export class TableOrderComponent {

  categories : CategoryWithProductItemsDto[] = []
  filteredCategories : CategoryWithProductItemsDto[] = []

  @Input()
  storeTable : StoreTableDto | null = null;
  @Input()
  storeSection: StoreSectionDto | null = null;

  constructor(private storefeedApiService: StorefeedApiService, private bottomSheet: MatBottomSheet, private staffOrderService : StaffOrderService) {
  }

  ngOnInit(): void {
      this.loadCategories()

    this.staffOrderService.ordersChanged.subscribe({
      next: res => {
        this.loadCategories()
      }
    })
  }

  loadCategories() {

    this.storefeedApiService.getCategoriesWithProducts().subscribe({
      next: res => {
        this.categories = res;
        this.filteredCategories = res;
        this.staffOrderService.initQuantityOfProduct(this.categories)
      },
      error: err => {

      }
    })
  }

  getQuantitySum() {
    return this.staffOrderService.getQuantitySum();
  }

  decreaseAmount(product : ProductDto, variant: ProductVariantDto | null) {
    this.staffOrderService.decreaseQuantityOfProduct(product, variant)
  }

  increaseAmount(product : ProductDto, variant: ProductVariantDto | null) {
    console.log("increase with product " + product  + " and variant " + variant)
    this.staffOrderService.increaseQuantityOfProduct(product, variant)
  }

  getQuantity(product : ProductDto, variant: ProductVariantDto | null): number {
    return this.staffOrderService.getQuantityOfProduct(product, variant)
  }

  openOrderDetails() {
    this.bottomSheet.open(OrderDetailSheetComponent, {
      data: {
        storeTableInputParam: this.storeTable?.id,
        sectionInputParam: this.storeSection?.id
      },
    });
  }

  openAddOption(product : ProductDto, variant: ProductVariantDto | null) {
    let sheet = this.bottomSheet.open(OrderItemOptionComponent, {
      data: {
        product: product,
        variant: variant
      },
    });



  }

  categorySelected($event: MatChipListboxChange) {

    let selectedCat : CategoryWithProductItemsDto = $event.value;

    if(selectedCat) {
      this.filteredCategories = [];
      this.filteredCategories.push(selectedCat)
    }
    else {
      this.filteredCategories = this.categories;
    }

  }
}
