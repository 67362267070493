import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {CategoryDto, CategoryWithProductItemsDto, ProductDto} from "../model/product.model";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class StorefeedApiService {

  constructor(private http: HttpClient) { }

  getCategories(tableCode : string, searchTerm: string | null) : Observable<CategoryDto[]>  {

    let param : any = {}
    if(searchTerm != null) {
      param.searchTerm = searchTerm;
    }
    return this.http.get<CategoryDto[]>(environment.baseurl + "v1/shopnow/category/" + tableCode + "/all", {params: param})
  }


  getProductsOfCategory(tableCode : string, categoryId : number, searchTerm: string | null) : Observable<ProductDto[]>  {

    let param : any = {}
    if(searchTerm != null) {
      param.searchTerm = searchTerm;
    }
    return this.http.get<ProductDto[]>(environment.baseurl + "v1/shopnow/product/" + tableCode  + "/" + categoryId + "/products", {params: param})
  }

  getCategoriesWithProducts() : Observable<CategoryWithProductItemsDto[]>  {

    return this.http.get<CategoryWithProductItemsDto[]>(environment.baseurl + "v1/shopnow/category/categorieswithproducts")
  }

}
