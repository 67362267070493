<div>

  <div>
    <mat-grid-list cols=10 rowHeight="1:3">

      <mat-grid-tile colspan="8">
        <mat-form-field appearance="fill">
          <mat-label>Ziel-Tisch</mat-label>
          <mat-select [(value)]="selectedStoreTable" style="width: 100%">
            <mat-option *ngFor="let table of targetTables" [value]="table">
              {{ table.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="moveTable()">
          <mat-icon>move_to_inbox</mat-icon>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div *ngFor="let order of orders" class="card">

    <p>{{order.orderNumber}} | {{order.createdAt | date : 'dd.MM.yyyy HH:mm' : '' : 'de'}}</p>
    <p style="font-size: 0.8rem" *ngIf="order.guestCode">{{order.guestName}} - {{order.guestCode}}</p>

    <div *ngFor="let orderPosition of order.positions" class="variantcard">

      <mat-grid-list cols="10" rowHeight="1:2">

        <mat-grid-tile colspan="1">
          <mat-checkbox (change)="markCheck($event, orderPosition)"></mat-checkbox>
        </mat-grid-tile>
        <mat-grid-tile colspan="6">
          {{ getProductName(orderPosition) }}  {{getProductAmount(orderPosition)}} {{'enum.unit.' + getProductUnit(orderPosition) | translate}} {{orderPosition.option}}
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          <span>€ {{getProductPrice(orderPosition) | number: '1.2-2':'de'}}</span>
        </mat-grid-tile>


      </mat-grid-list>

    </div>

  </div>

  <div *ngIf="!orders || orders.length == 0"  class="card">

    <p>Es gibt aktuell keine Bestellungen zu diesem Tisch</p>

  </div>



</div>
