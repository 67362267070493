<div *ngFor="let orderItem of orders"
     style="margin-top: 0.5rem; border-bottom: 1px; border-bottom-color: black; border-bottom-style: solid">

  <mat-grid-list cols=5 rowHeight="2:1">

    <mat-grid-tile>
      {{ orderItem.quantity }} x
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <span>{{ orderItem.product.name }}</span><span
      *ngIf="orderItem.variant && orderItem.variant.name">{{ ' - ' + orderItem.variant?.name }}</span>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="removeItem(orderItem)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-grid-tile>
    <mat-grid-tile colspan="5" *ngIf="!orderItem.variant">
      {{ orderItem.product.amountUnit | specialamount }} {{ 'enum.unit.' + orderItem.product.unit | translate }}
    </mat-grid-tile>
    <mat-grid-tile colspan="5" *ngIf="orderItem.variant">
      {{ orderItem.variant.amountUnit | specialamount }} {{ 'enum.unit.' + orderItem.variant.unit | translate }}
    </mat-grid-tile>
    <mat-grid-tile colspan="5" *ngIf="orderItem.option">
      {{ orderItem.option }}
    </mat-grid-tile>

  </mat-grid-list>
</div>

<mat-grid-list cols="3" rowHeight="2:1" style="margin-top: 1rem" *ngIf="guestTracking.guestTrackingEnabled">
  <mat-grid-tile colspan="3" >
    <mat-form-field style="margin-top: 0.5rem">
      <mat-label>Gast-Nummer</mat-label>
      <input matInput maxlength="3" placeholder="..." [(ngModel)]="guestTrackingCode" (ngModelChange)="guestTrackingCode = $event.toLowerCase()">
    </mat-form-field>
  </mat-grid-tile>
</mat-grid-list>

<div *ngIf="!orders || orders.length == 0"><h3>Keine Bestellungen vorhanden</h3></div>

<div class="button" style="margin-top: 1rem;" *ngIf="orders && orders.length > 0">
  <button mat-fab extended [disabled]="orderInProcess" (click)="sendOrder()"
          style="background: transparent; margin-top: 0.2rem; align-self: center">
    <mat-icon>send</mat-icon>
    Bestellen
  </button>
</div>




