import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {environment} from "../../environments/environment";
import {
  SignupPersonalInputDto,
  SignupPhoneVerificationInputDto,
  SignupUserExistingAppDataInputDto
} from "../model/signup/signup.model.spec";
import {TokenResultDto} from "../model/auth/tokenresult.model";
import {KiUserDto} from "../model/kiuser/kiuser.model";



@Injectable({
  providedIn: 'root'
})
export class SignupApiService {

  constructor(private http: HttpClient) { }

  start(signupPersonalInputDto : SignupPersonalInputDto) {
    signupPersonalInputDto.shopnow = true
    return this.http.post<TokenResultDto>(environment.baseurl + "v1/signup", signupPersonalInputDto)
  }

  verifyPhone(signupPhoneVerificationInputDto : SignupPhoneVerificationInputDto) {
    return this.http.post<KiUserDto>(environment.baseurl + "v1/signup/verifyphone", signupPhoneVerificationInputDto)
  }

  resendPhone() {
    return this.http.put(environment.baseurl + "v1/signup/resendphone", {})
  }

  signupUserWithExistingApp(signupStoreDataInputDto : SignupUserExistingAppDataInputDto) {
    return this.http.post<TokenResultDto>(environment.baseurl + "v1/signup/shopnow/existingapp", signupStoreDataInputDto)
  }

}
