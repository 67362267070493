import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CategoryDto, StoreSectionDto, StoreTableDto} from "../../model/product.model";
import {StorefeedApiService} from "../../api/storefeed-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StoretableApiService} from "../../api/storetable-api.service";
import {AuthService} from "../../services/auth.service";
import {StoreSectionApiService} from "../../api/storesection-api.service";

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnInit{

  userName: string = ""
  storeName: string = ""
  tables : StoreTableDto[] = []

  sectionId : number | null = null;
  section: StoreSectionDto | null = null

  constructor(private storeTableApiService: StoretableApiService,
              private storeSectionApiService : StoreSectionApiService,
              private route: ActivatedRoute, private router : Router,
              private authService : AuthService) {
  }

  ngOnInit(): void {
    if (this.route.params) {
      this.route.params.subscribe(params => {
        this.sectionId = params["sectionId"];
        if (this.sectionId) {
          this.load()
        }
      });
    }
    this.authService.getLoggedIn().subscribe({
      next: res => {
        this.userName = res.firstname
      }
    });

    this.authService.getLoggedInStore().subscribe({
      next: res => {
        this.storeName = res.displayName
      }
    })

    this.load();
  }

  load() {

    this.storeTableApiService.getAllForSection(Number(this.sectionId)).subscribe({
      next: res => {
        this.tables = res;
        this.loadSection()
      },
      error: err => {

      }

    })

  }

  loadSection() {

    this.storeSectionApiService.getById(Number(this.sectionId)).subscribe({
      next: res => {
        this.section = res;
      },
      error: err => {

      }
    })

  }

  openTableDetails(table: StoreTableDto) {
      this.router.navigateByUrl("/staff/table-detail/" + table.id + "/" + this.sectionId)
  }

  back() {
    this.router.navigateByUrl("/staff")
  }
}

