import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {StaffOrderService} from "../../services/stafforder.service";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ProductDto, ProductOptionDto, ProductVariantDto} from "../../model/product.model";
import {NotificationService} from "../../services/notification.service";
import {map, Observable, startWith, Subject} from "rxjs";

@Component({
  selector: 'app-order-item-option',
  templateUrl: './order-item-option.component.html',
  styleUrls: ['./order-item-option.component.scss']
})
export class OrderItemOptionComponent implements OnInit{

  validateForm!: UntypedFormGroup;
  product: ProductDto | null = null;
  variant: ProductVariantDto | null = null;

  filteredOptions: Observable<ProductOptionDto[]> = new Observable<ProductOptionDto[]>();

  public orderClicked = new Subject<number>();

  constructor(private fb: UntypedFormBuilder, private staffOrderService : StaffOrderService, @Inject(MAT_BOTTOM_SHEET_DATA) public selection: any,
              private notification : NotificationService, private bottomSheetRef: MatBottomSheetRef) {

    this.product = selection.product;
    this.variant = selection.variant;

  }


  ngOnInit(): void {
    this.validateForm = this.fb.group({
      option: [null, [Validators.required]],
      quantity: [null, [Validators.required]]
    });

    this.validateForm.controls['quantity'].patchValue(1)

    this.filteredOptions = this.validateForm.controls['option'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): ProductOptionDto[] {
    const filterValue = value.toLowerCase();

    return this.product!.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  submit() {
    if (this.validateForm.valid) {
      this.staffOrderService.update(this.product!, this.variant, this.validateForm.controls['option'].value, this.validateForm.controls['quantity'].value)
      this.notification.showNotification("Produkte hinzugefügt")
      this.bottomSheetRef.dismiss()
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  resetOption() {
    this.validateForm.controls["option"].reset()
  }
}
