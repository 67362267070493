import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {StoreSectionDto, StoreTableDto} from "../model/product.model";
import {Observable} from "rxjs";
import {
  ShopnowOrderWithPositionsDto,
  ShopnowStaffMoveOrdersInputDto,
  ShopnowStoreConfigDto,
  StaffCancelGuestInputDto,
  StaffCancelInputDto,
  StaffMarkCashinGuestInputDto,
  StaffMarkCashinInputDto,
  StoreGuestDto
} from "../model/order.model";


@Injectable({
  providedIn: 'root'
})
export class StoreGuestApiService {

  constructor(private http: HttpClient) { }

  getById(id : number) : Observable<StoreGuestDto>  {
    return this.http.get<StoreGuestDto>(environment.baseurl + "v1/storeguest/" + id)
  }

  getByCode(code : string) : Observable<StoreGuestDto>  {
    return this.http.get<StoreGuestDto>(environment.baseurl + "v1/storeguest/code/" + code)
  }

  getConfig(tableCode : string) {
    return this.http.get<ShopnowStoreConfigDto>(environment.baseurl + "v1/shopnow/agent/store/config/table/" + tableCode)
  }

  getConfigAsStaff() {
    return this.http.get<ShopnowStoreConfigDto>(environment.baseurl + "v1/shopnow/agent/store/config")
  }

  getOpenOrdersForCashin(id : number) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.get<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storeguest/" + id + "/openorders/cashin")
  }

  markCashin(input : StaffMarkCashinGuestInputDto) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.post<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storeguest/markcashin", input)
  }

  cancelPositions(input : StaffCancelGuestInputDto) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.post<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storeguest/cancelpositions", input)
  }

}
