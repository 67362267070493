import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ShoppingcartService} from "../services/shoppingcart.service";
import {StoretableApiService} from "../api/storetable-api.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {LoginInputDto} from "../model/auth/logininput.model";
import {GuesttrackingService} from "../services/guesttracking.service";

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  activeTab = 0;
  tableName: string = ""
  storeName: string = ""

  validateForm!: UntypedFormGroup;

  tableCode : string | null = null

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,
              private router : Router,
              public shoppingCartService : ShoppingcartService, private storeTableApiService : StoretableApiService, private guestTracking : GuesttrackingService){

  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.tableCode = params['tableCode'];

      if(this.tableCode) {
        localStorage.setItem("tableCode", this.tableCode!);

        this.storeTableApiService.get(this.tableCode!).subscribe({
          next: res => {
            this.tableName = res.name
            this.storeName = res.storeName
          }
        })

        this.guestTracking.loadPublic(this.tableCode)

      }

    });


    this.validateForm = this.fb.group({
      tableCode: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
    });

    this.shoppingCartService.load().subscribe()


  }


  openProducts() {
    this.activeTab = 0;
  }

  openShoppingCart() {
    this.activeTab = 1;
  }

  openOrderOverview() {
    this.activeTab = 2;
  }

  submitTableCode() {
    if (this.validateForm.valid) {
      this.router.navigateByUrl("/public?tableCode=" + this.validateForm.controls['tableCode'].value)

    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  openStaff() {
    this.router.navigateByUrl("/staff")
  }
}
