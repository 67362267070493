<mat-toolbar>
  <button mat-fab extended style="background: transparent; margin-top: 0.2rem; align-self: center" (click)="back()"><mat-icon>arrow_back</mat-icon></button>
  <span style="font-size: 0.8rem; margin-left: 0.5rem">{{section?.name}} / {{ storeTable?.name }} / {{userName}}</span>
</mat-toolbar>

<div class="menu-tabs">
  <mat-grid-list cols="4" rowHeight="4:1">
    <mat-grid-tile [class]="activeTab == 0 ? 'selected' : ''"  (click)="openOrder()"><span style="font-size: 0.7rem">Aufnehmen</span></mat-grid-tile>
    <mat-grid-tile [class]="activeTab == 1 ? 'selected' : ''" (click)="openOpenOrders()"><span style="font-size: 0.7rem">Bestellungen</span></mat-grid-tile>
    <mat-grid-tile [class]="activeTab == 2 ? 'selected' : ''" (click)="openCashin()"><span style="font-size: 0.7rem">Abrechnen</span></mat-grid-tile>
    <mat-grid-tile [class]="activeTab == 3 ? 'selected' : ''" (click)="openMove()"><span style="font-size: 0.7rem">Verschieben</span></mat-grid-tile>
  </mat-grid-list>
</div>


<app-table-order *ngIf="activeTab==0" [storeTable]="storeTable" [storeSection]="section"></app-table-order>
<app-table-open-order-list *ngIf="activeTab==1" [storeTable]="storeTable"></app-table-open-order-list>
<app-table-cashin *ngIf="activeTab==2" [storeTable]="storeTable"></app-table-cashin>
<app-table-move *ngIf="activeTab==3" [storeTable]="storeTable" [sectionId]="sectionId"></app-table-move>
