import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

import {Observable, throwError} from "rxjs";
import {catchError, mergeMap} from "rxjs/operators";
import {AuthService} from "../services/auth.service";
import {NotificationService} from "../services/notification.service";



@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private isRefreshing = false;

  constructor(private authService: AuthService, private notificationService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({withCredentials: true});

    console.log("token is expired " + this.authService.isExpired() + " and refreshing is " + this.isRefreshing)

    if (this.authService.isExpired() && !this.isRefreshing && !request.url.includes("/assets")) {
      this.isRefreshing = true;
      let requestHandler = this.authService.refreshLogin().pipe(mergeMap(res => {
          this.isRefreshing = false;
          console.log("using replaced token for call " + request.url)
          return next.handle(request).pipe(
            catchError((err) => {
              this.isRefreshing = false;
              if (err instanceof HttpErrorResponse) {
                if (err.status === 500) {
                  this.notificationService.showNotification("Es ist ein unbekannter Fehler aufgetreten")
                }
              }
              return throwError(err);
            })
          )
        }),
        catchError((err) => {
          this.isRefreshing = false;
          console.log("Error on Refresh Login " + err)
          return throwError(err);
        }))
      return requestHandler;
    } else {
      return next.handle(request).pipe(
        catchError((err) => {

          if (err instanceof HttpErrorResponse) {
            if (err.status === 500) {
              this.notificationService.showNotification("Es ist ein unbekannter Fehler aufgetreten")
            }
          }
          return throwError(err);
        })
      )
    }
  }


}
