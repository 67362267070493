<mat-toolbar>

  <span style="width: 90%; font-size:0.9rem;">{{ storeName }} / {{userName}}</span>

  <div>
    <mat-icon style="align-self: flex-end" (click)="logout()">logout</mat-icon>
  </div>
</mat-toolbar>


<div *ngIf="guestTracking.guestTrackingEnabled" class="actioncard" (click)="openGuestCashin()">
  <p>Gast abrechnen</p>
</div>

<h4 style="align-self: center; width: 100%; margin-top: 1rem">Bereiche</h4>

<div>
  <app-section-list></app-section-list>
</div>

