import {Component, OnInit} from '@angular/core';
import {ShoppingCartDto, ShoppingCartProductItemDto} from "../../model/shoppingcart.model";
import {ShoppingcartService} from "../../services/shoppingcart.service";
import {NotificationService} from "../../services/notification.service";


@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  orderInProcess : boolean = false;

  cart: ShoppingCartDto = {
    cartItems: [],
    productCount: 0,
    sum: 0
  }

  constructor(private shoppingCartService: ShoppingcartService, private notification :NotificationService) {
  }

  ngOnInit(): void {

    this.shoppingCartService.shoppingCartChanged.subscribe({
        next: value => {
          this.cart = this.shoppingCartService.cart
        }
      }
    );

   this.load();


  }

  load() {
    this.shoppingCartService.load().subscribe({
      next: res => {
        res.cartItems = res.cartItems.filter(ci => ci.quantity > 0);
        this.cart = res;
      }
    })
  }

  order() {
    this.orderInProcess = true;
    this.shoppingCartService.order(localStorage.getItem("tableCode")!).subscribe({
      next: res => {
        this.notification.showNotification("Die Bestellung wurde abgeschickt")
        this.load();
        this.orderInProcess = false;
      }, error: err => {
        this.notification.showNotification("Es gab einen Fehler bei der Bestellung");
        this.orderInProcess = false;
      }

    })
  }

  getProductName(item : ShoppingCartProductItemDto) {
    if(item.variant && item.variant.name) return item.variant.name + " (" + item.product.name + ")";
    return item.product.name;
  }

  getProductAmount(item : ShoppingCartProductItemDto) {
    if(item.variant) return item.variant.amountUnit;
    return item.product.amountUnit;
  }

  getProductUnit(item : ShoppingCartProductItemDto) {
    if(item.variant) return item.variant.unit;
    return item.product.unit;
  }

  getProductPrice(item : ShoppingCartProductItemDto) {
    if(item.variant) return item.variant.priceAmount;
    return item.product.priceAmount;
  }

  remove(cartItem: ShoppingCartProductItemDto) {
    this.shoppingCartService.remove(cartItem);
  }
}
