<div>

  <form [formGroup]="validateForm" (ngSubmit)="submit()">
    <p>
      <mat-form-field>
        <input type="text"
               aria-label="Option"
               matInput
               formControlName="option"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span style="margin-left: 1rem; margin-bottom: 1rem; align-self: center" class="material-icons" (click)="resetOption()">cancel</span>
    </p>

    <p>
      <mat-form-field>
        <input type="number" matInput placeholder="Anzahl" formControlName="quantity">
      </mat-form-field>
    </p>

    <div class="button">
      <button type="submit" mat-button>Hinzufügen</button>
    </div>

  </form>
</div>
