<button mat-fab extended style="background: transparent; margin-top: 0.2rem; align-self: center" (click)="back()"><mat-icon>arrow_back</mat-icon>Kategorien</button>

<div *ngFor="let product of products" class="card">

  <mat-grid-list cols=5 rowHeight="2:1" (click)="openProductDetails(product, null)">

    <mat-grid-tile colspan="3" style="padding: 0.8rem">
      <span style="width: 100%; align-self: start">{{ product.name }}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
          {{product.amountUnit | specialamount}} {{'enum.unit.' + product.unit | translate}}
    </mat-grid-tile>
    <mat-grid-tile colspan="5" *ngIf="product.description">
      <span style="width: 100%; align-self: start; margin-top: 1rem; font-style: italic">{{product.description}}</span>
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
      <span style="font-size: 1rem">€ {{product.priceAmount | number: '1.2-2':'de'}}</span>
    </mat-grid-tile>

  </mat-grid-list>

  <div *ngFor="let variant of product.variants" class="variantcard">
    <mat-grid-list cols=3 rowHeight="2:1" (click)="openProductDetails(product, variant)">

      <mat-grid-tile>
        <span style="font-size: 0.8rem">{{ variant.name }}</span>
      </mat-grid-tile>
      <mat-grid-tile>
        {{variant.amountUnit | specialamount}} {{'enum.unit.' + variant.unit | translate}}
      </mat-grid-tile>
      <mat-grid-tile>
        <span>€ {{variant.priceAmount | number: '1.2-2':'de'}}</span>
      </mat-grid-tile>

    </mat-grid-list>
  </div>
</div>

<div *ngIf="!products || products.length === 0"  class="card">

  <p>Aktuell gibt es keine Produkte in dieser Kategorie</p>

</div>



