import {Injectable} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthApiService} from "../api/auth-api.service";
import {Observable, Subject} from "rxjs";
import * as uuid from 'uuid';
import {TokenResultDto} from "../model/auth/tokenresult.model";
import {
  LoginCodeResultDto,
  LoginInputDto,
  LoginWithFlowInputDto,
  StartLoginFlowInputDto
} from "../model/auth/logininput.model";
import {KiUserDto} from "../model/kiuser/kiuser.model";
import {StoreDto} from "../model/store/store.model";
import {DecodedToken} from "../model/jwt/jwt.model";
import {KiUserApiService} from "../api/kiuser-api.service";
import {StoreApiService} from "../api/store-api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private jwtHelper = new JwtHelperService();
  public authStateChanged = new Subject();
  loggedInUser: KiUserDto | null = null
  loggedInStore: StoreDto | null = null

  constructor(private authApiService: AuthApiService, private kiUserApiService: KiUserApiService, private storeApiService : StoreApiService) {
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (token == null || token == undefined) return false;
    return true;
  }

  public isExpired(): boolean {
    const token = this.getAuthToken();
    return token != null && this.jwtHelper.isTokenExpired(token);
  }

  public isVerified(): boolean {
    return this.loggedInUser != null && this.loggedInUser.verified == true;
  }

  public isAdmin(): boolean {
    const token = localStorage.getItem('token');
    if (token == null || token == undefined) return false;

    var decodedToken : DecodedToken = this.parseJwt(token);
    return decodedToken.admin;
  }

  public startLoginFlow(loginInput: StartLoginFlowInputDto): Observable<LoginCodeResultDto> {
    return this.authApiService.startLoginFlow(loginInput);
  }

  public loginWithFlow(loginInput: LoginWithFlowInputDto): Observable<Boolean> {

    const observable = new Observable<Boolean>(observer => {
      this.authApiService.loginWithFlow(loginInput).subscribe({
        next: res => {
          localStorage.setItem("token", res.jwt)
          localStorage.setItem("refreshtoken", res.refreshJwt)

          this.getLoggedInStore().subscribe(res => {

          });

          this.authStateChanged.next(null);
          observer.next(true);
        },
        error: error => {
          this.authStateChanged.next(null);
          observer.next(false);
        }
      })
    });

    return observable;
  }

  public isStoreAdmin(): boolean {
    const token = localStorage.getItem('token');
    if (token == null || token == undefined) return false;

    var decodedToken : DecodedToken = this.parseJwt(token);
    return decodedToken.storeId !== null;
  }

  public setToken(tokenResult: TokenResultDto) {
    localStorage.setItem("token", tokenResult.jwt)

    if(tokenResult.refreshJwt) {
      localStorage.setItem("refreshtoken", tokenResult.refreshJwt)
    }
  }

  public finishSignup() {
    this.authStateChanged.next(null);
  }

  public login(loginInput: LoginInputDto): Observable<Boolean> {

    loginInput.deviceId = this.getDeviceId();

    const observable = new Observable<Boolean>(observer => {
      this.authApiService.login(loginInput).subscribe(tokenResult => {
        localStorage.setItem("token", tokenResult.jwt)
        localStorage.setItem("refreshtoken", tokenResult.refreshJwt)

        this.getLoggedIn().subscribe(res => {
          this.loggedInUser = res;
        })
        this.getLoggedInStore().subscribe(res => {
          this.loggedInStore = res;
        });

        this.authStateChanged.next(null);
        observer.next(true);
      }, error => {
        this.authStateChanged.next(null);
        observer.next(false);
      });
    });

    return observable;
  }

  public checkLogin(): Observable<KiUserDto> {

    const observable = new Observable<KiUserDto>(observer => {
      this.kiUserApiService.loadLoggedIn().subscribe(loggedIn => {
        console.log("setting logged in")
        this.loggedInUser = loggedIn;

        this.storeApiService.getLoggedInStore().subscribe(res => {
          this.authStateChanged.next(null);
          this.loggedInStore = res
          observer.next(loggedIn);
          observer.complete()
        })
      }, err => {
        this.logout()
        observer.complete()
      });
    });

    return observable;
  }

  public refreshLogin(): Observable<KiUserDto> {

    const observable = new Observable<KiUserDto>(observer => {

      this.authApiService.refreshLogin({
          deviceId: this.getDeviceId(),
          refreshToken: this.getRefreshToken()!,
          shopnow: true
        }).subscribe({
          next: res => {
            localStorage.setItem("token", res.jwt)
            localStorage.setItem("refreshtoken", res.refreshJwt)
            this.authStateChanged.next(null);
            this.kiUserApiService.loadLoggedIn().subscribe({
              next: res => {
                observer.next(res)
              }
            })
          },
          error: () => {
            observer.error()
          }
        })
    });
    return observable;
  }


  getLoggedIn(): Observable<KiUserDto> {
    const observable = new Observable<KiUserDto>(observer => {
      this.kiUserApiService.loadLoggedIn().subscribe(kiUser => {
        this.loggedInUser = kiUser
        observer.next(kiUser)
        observer.complete();
      });
    });

    return observable;
  }

  getLoggedInStore(): Observable<StoreDto> {

    const observable = new Observable<StoreDto>(observer => {
      this.storeApiService.getLoggedInStore().subscribe({
        next: store => {
          this.loggedInStore = store;
          this.authStateChanged.next(null)
          observer.next(store)
          observer.complete()
        },
        error: err=> {
          observer.error(err);
        }
      });
    });

    return observable;
  }

  getDeviceId() : string {
    var deviceId : string | null= localStorage.getItem('deviceId');
    if(deviceId == null) {
      deviceId = uuid.v4()
      localStorage.setItem("deviceId", deviceId.toString());
    }
    return deviceId;
  }

  getAuthToken(): string | null {
    return localStorage.getItem('token')
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshtoken')
  }

  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshtoken");
    this.authStateChanged.next(null);
  }

  parseJwt (token: string) : DecodedToken {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }




}
