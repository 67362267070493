<div class="parent" *ngIf="current==0">
  <form [formGroup]="personalForm" (ngSubmit)="startLoginFlow()">


    <h5>Geben sie hier ihre E-Mail Adresse ein</h5>

    <mat-form-field style="width: 100%" required>
      <input type="mail" matInput placeholder="E-Mail Adresse*" formControlName="mail">
    </mat-form-field>



    <button mat-button style="width: 100%">Fortfahren</button>
  </form>
</div>

<!-- Phone Number Verification-->
<div class="parent" *ngIf="current==1">

  <div style="width: 100%">

    <div style="min-width: 25rem; max-width: 100%">

      <div style="width: 100%;  margin-top: 0.5rem">
        <p>Geben sie hier den 6-stelligen Code ein, den wir an deine Mobiltelefonnummer gesendet haben:</p>
      </div>

      <div style="width: 100%;  margin-top: 0.5rem;">
        <div>
          <code-input
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
      </div>
      <div style="width: 100%;  margin-top: 0.5rem;">
        {{signupService.devToken}}
      </div>
      <div style="width: 100%; margin-top: 0.5rem">
        <div>
          <button mat-button [disabled]="!phoneVerified" (click)="continuePhoneVerification()" style="width: 100%">{{'ui.button.continue.label' | translate}}</button>
        </div>
      </div>
      <div style="width: 100%; margin-top: 0.5rem">
        <div>
          <button mat-button *ngIf="!phoneVerified"  (click)="resendPhoneCode()" style="width: 100%">{{'ui.button.resend.label' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Phone Number Verification-->
<div class="parent" *ngIf="current==2">

  <form [formGroup]="passwordForm" (ngSubmit)="submitPasswordForm()">


    <h5>Legen sie hier ihr Passwort fest:</h5>

    <mat-form-field style="width: 100%" required>
      <input type="password" matInput placeholder="Password" formControlName="password">
    </mat-form-field>

    <mat-form-field style="width: 100%" required>
      <input type="password" matInput placeholder="Passwort wiederholen" formControlName="passwordrepeat">
    </mat-form-field>


    <button mat-button style="width: 100%">Fortfahren</button>
  </form>

</div>

