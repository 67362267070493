import {Component, OnInit} from '@angular/core';
import {StoretableApiService} from "../../api/storetable-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {StoreSectionDto, StoreTableDto} from "../../model/product.model";
import {AuthService} from "../../services/auth.service";
import {StoreSectionApiService} from "../../api/storesection-api.service";

@Component({
  selector: 'app-table-detail',
  templateUrl: './table-detail.component.html',
  styleUrls: ['./table-detail.component.scss']
})
export class TableDetailComponent implements OnInit {

  activeTab = 0;
  userName: string = ""
  storeName: string = ""

  id: string | null = "";
  sectionId: number | null = null
  storeTable: StoreTableDto | null = null
  section: StoreSectionDto | null = null

  constructor(private storeTableApiService: StoretableApiService, private authService: AuthService,
              private storeSectionApiService: StoreSectionApiService,
              private router: Router, private route: ActivatedRoute, private notificationService: NotificationService,) {

  }

  ngOnInit(): void {
    if (this.route.params) {
      this.route.params.subscribe(params => {
        this.id = params["id"];
        this.sectionId = Number(params["sectionId"])
        if (this.id) {
          this.load()
        }
      });
    }
    this.authService.getLoggedIn().subscribe({
      next: res => {
        this.userName = res.firstname
      }
    });

    this.authService.getLoggedInStore().subscribe({
      next: res => {
        this.storeName = res.displayName
      }
    })

  }

  private load() {
    this.storeTableApiService.getById(Number(this.id)).subscribe({
      next: res => {
        this.storeTable = res;
        this.loadSection()
      }
    })
  }

  private loadSection() {
    this.storeSectionApiService.getById(this.sectionId!).subscribe({
      next: res => {
        this.section = res;
      }
    })

  }


  openOrder() {
    this.activeTab = 0;
  }

  openOpenOrders() {
    this.activeTab = 1;
  }

  openCashin() {
    this.activeTab = 2;
  }

  back() {
    this.router.navigateByUrl("/staff/table-list/" + this.sectionId)
  }

  openMove() {
    this.activeTab = 3;
  }
}
