import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {PublicComponent} from './public.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {ShoppingFeedComponent} from "./shopping-feed/shopping-feed.component";
import {ProductDetailSheetComponent} from "./product-detail-sheet/product-detail-sheet.component";
import {ProductListComponent} from "./product-list/product-list.component";
import {CategoryListComponent} from "./category-list/category-list.component";
import {OpenOrderOverviewComponent} from "./open-order-overview/open-order-overview.component";
import {ShoppingCartComponent} from "./shopping-cart/shopping-cart.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTabsModule} from "@angular/material/tabs";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatChipsModule} from "@angular/material/chips";
import {PublicRoutingModule} from "./public-routing.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatStepperModule} from "@angular/material/stepper";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatBadgeModule} from "@angular/material/badge";
import {UtilModule} from "../util/util.module";


@NgModule({
  declarations: [
    PublicComponent,
    ShoppingFeedComponent,
    ProductDetailSheetComponent,
    ProductListComponent,
    CategoryListComponent,
    OpenOrderOverviewComponent,
    ShoppingCartComponent
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatToolbarModule,
    MatListModule,
    MatProgressBarModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    MatGridListModule,
    MatTabsModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatChipsModule,
    HttpClientModule,
    TranslateModule,
    DecimalPipe,
    MatSelectModule,
    MatBadgeModule,
    UtilModule
  ],
  exports: [PublicComponent, CategoryListComponent, ProductListComponent, OpenOrderOverviewComponent, ShoppingFeedComponent, ShoppingCartComponent]
})
export class PublicModule { }
