<!--
<mat-toolbar>
  <span>{{ storeName }} / {{tableName}}</span>
</mat-toolbar>

<div class="menu-tabs">
  <mat-grid-list cols="3" rowHeight="5:1">
    <mat-grid-tile [class]="activeTab == 0 ? 'selected' : ''"  (click)="openProducts()">Karte</mat-grid-tile>
    <mat-grid-tile [class]="activeTab == 1 ? 'selected' : ''" (click)="openShoppingCart()">Bestellung - {{shoppingCartService.cart.productCount > 0 ? shoppingCartService.cart.productCount : ''}}</mat-grid-tile>
    <mat-grid-tile [class]="activeTab == 2 ? 'selected' : ''" (click)="openOrderOverview()">Rechnung</mat-grid-tile>
  </mat-grid-list>
</div>


<div>
  <app-shopping-feed *ngIf="activeTab == 0"></app-shopping-feed>
  <app-shopping-cart *ngIf="activeTab == 1"></app-shopping-cart>
  <app-open-order-overview *ngIf="activeTab == 2"></app-open-order-overview>
</div>
-->
<div style="width: 100%">
  <router-outlet></router-outlet>
</div>
