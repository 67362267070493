import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";


import {AuthService} from "./auth.service";
import {KiUserApiService} from "../api/kiuser-api.service";
import {
  SignupPersonalInputDto,
  SignupPhoneVerificationInputDto,
  SignupUserExistingAppDataInputDto
} from "../model/signup/signup.model.spec";
import {KiUserDto} from "../model/kiuser/kiuser.model";
import {SignupApiService} from "../api/signup-api.service";


@Injectable({
  providedIn: 'root'
})
export class SignupService {

  public devToken: string | null = null;
  public signupActive : boolean = false;

  constructor(private signupApiService: SignupApiService, private authService: AuthService, private kiUserApiService: KiUserApiService) {
  }

  public start(singupPersonal: SignupPersonalInputDto): Observable<KiUserDto> {

    // clean everything before signup
    this.authService.logout();
    this.signupActive = true;
    singupPersonal.deviceId = this.authService.getDeviceId();

    const observable = new Observable<KiUserDto>(observer => {
      this.signupApiService.start(singupPersonal).subscribe({
        next: tokenResult => {
          this.authService.setToken(tokenResult)
          this.devToken = tokenResult.devPhoneCode;
          this.kiUserApiService.loadLoggedIn().subscribe({
              next: res => {
                observer.next(res)
              },
              error: err => {
                observer.error(err);
              }
            }
          )
        },
        error: err => {
          observer.error(err)
        }
      })
    });

    return observable;
  }

  public signupUserExistingApp(signupStoreInput: SignupUserExistingAppDataInputDto): Observable<KiUserDto> {

    const observable = new Observable<KiUserDto>(observer => {
      this.signupApiService.signupUserWithExistingApp(signupStoreInput).subscribe({
        next: tokenResult => {
          this.authService.setToken(tokenResult)
          this.kiUserApiService.loadLoggedIn().subscribe(kiUser => {
            observer.next(kiUser)
          })
          this.authService.getLoggedInStore().subscribe(res => {

          })
        },
        error: err => {
          observer.error(err);
        }
      })
    });
    return observable;
  }


  public resendPhone() {
    return this.signupApiService.resendPhone();
  }

  public verifyPhone(signupPhoneVerificationInput: SignupPhoneVerificationInputDto): Observable<KiUserDto> {
    return this.signupApiService.verifyPhone(signupPhoneVerificationInput);
  }



  public finish() {
    this.signupActive = false;
    this.authService.finishSignup()
  }

  public isStoreAdmin() {
    return this.authService.isStoreAdmin();
  }

  public startWithExistingApp() {
    this.authService.logout();
    this.signupActive = true;
  }

}
