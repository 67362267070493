import { Injectable } from '@angular/core';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ShopnowSessionService {

  constructor() {
  }

  getShopSessionId() : string {
    let id = localStorage.getItem("shopnow-session-id");

    if(id) {
      return id;
    }

    id = uuid.v4();
    localStorage.setItem("shopnow-session-id", id);
    return id;
  }



}
