import {Component, OnInit} from '@angular/core';
import {ShopnowOrderPositionDto, ShopnowOrderWithPositionsDto} from "../../model/order.model";
import {OrderApiService} from "../../api/order-api.service";
import {NotificationService} from "../../services/notification.service";


@Component({
  selector: 'app-open-order-overview',
  templateUrl: './open-order-overview.component.html',
  styleUrls: ['./open-order-overview.component.scss']
})
export class OpenOrderOverviewComponent implements OnInit{

  orders: ShopnowOrderWithPositionsDto[] = []
  overallSum : number = 0

  constructor(private orderApiService: OrderApiService, private notification : NotificationService) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.orderApiService.getOpenOrders().subscribe({
      next: res => {
        this.orders = res;
        this.overallSum = 0
        for(let o of res) {
          this.overallSum += o.sum;
        }
      }
    })
  }

  demandCashin() {
    this.orderApiService.demandCashin().subscribe( {
      next: res => {
        this.notification.showNotification("Das Servicepersonal wird über den Bezahlwunsch informiert")
      }
    })
  }

  getProductName(item : ShopnowOrderPositionDto) {
    if(item.variant && item.variant.name) return item.variant.name + " (" + item.product.name + ")";
    return item.product.name;
  }

  getProductAmount(item : ShopnowOrderPositionDto) {
    if(item.variant) return item.variant.amountUnit;
    return item.product.amountUnit;
  }

  getProductUnit(item : ShopnowOrderPositionDto) {
    if(item.variant) return item.variant.unit;
    return item.product.unit;
  }

}
