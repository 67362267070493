import {Component, Input, OnInit} from '@angular/core';
import {StoreSectionDto, StoreTableDto} from "../../model/product.model";
import {ShopnowOrderPositionDto, ShopnowOrderWithPositionsDto, ShopnowPaymentMethod} from "../../model/order.model";
import {StoretableApiService} from "../../api/storetable-api.service";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-table-move',
  templateUrl: './table-move.component.html',
  styleUrls: ['./table-move.component.scss']
})
export class TableMoveComponent implements OnInit {


  @Input()
  storeTable: StoreTableDto | null = null;
  @Input()
  sectionId: number | null = null;

  orders: ShopnowOrderWithPositionsDto[] = []

  markedPositions: number[] = []

  selectedStoreTable: StoreTableDto | null = null

  targetTables: StoreTableDto[] = []


  constructor(private storeTableApiService: StoretableApiService, private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.storeTableApiService.getOpenOrdersForCashin(this.storeTable!.id).subscribe({
      next: res => {
        this.orders = res;
        this.loadTables()
      }
    })
  }

  loadTables() {
    this.storeTableApiService.getAll().subscribe({
      next: res => {
        this.targetTables = res.filter(t => t.id !== this.storeTable?.id)
      }
    })
  }


  getProductName(item: ShopnowOrderPositionDto) {
    if (item.variant && item.variant.name) return item.variant.name + " (" + item.product.name + ")";
    return item.product.name;
  }

  getProductAmount(item: ShopnowOrderPositionDto) {
    if (item.variant) return item.variant.amountUnit;
    return item.product.amountUnit;
  }

  getProductUnit(item: ShopnowOrderPositionDto) {
    if (item.variant) return item.variant.unit;
    return item.product.unit;
  }


  markCheck(event: any, orderPosition: ShopnowOrderPositionDto) {
    if (event.checked) {
      if (orderPosition.id) {
        this.markedPositions.push(orderPosition.id)
      }
    } else {
      if (orderPosition.id) {
        this.markedPositions.splice(this.markedPositions.lastIndexOf(orderPosition.id))
      }
    }
  }

  getProductPrice(orderPosition: ShopnowOrderPositionDto) {
    if (orderPosition.variant) return orderPosition.variant.priceAmount;
    return orderPosition.product.priceAmount;
  }

  moveTable() {

    if (!this.selectedStoreTable) {
      this.notification.showNotification("Zieltisch wählen");
      return;
    }

    this.storeTableApiService.moveTable({
      storeTableId: this.storeTable!.id,
      targetStoreTableId: this.selectedStoreTable.id,
      orderPositionIdsToMove: this.markedPositions,
    }).subscribe({
      next: res => {
        this.load()
        this.markedPositions = []
        this.selectedStoreTable = null
      }
    })
  }

}
