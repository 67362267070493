import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ProductDto, ProductOptionDto, ProductVariantDto} from "../../model/product.model";
import {ShoppingcartService} from "../../services/shoppingcart.service";
import {NotificationService} from "../../services/notification.service";
import {GuesttrackingService} from "../../services/guesttracking.service";


@Component({
  selector: 'app-product-detail-sheet',
  templateUrl: './product-detail-sheet.component.html',
  styleUrls: ['./product-detail-sheet.component.scss']
})
export class ProductDetailSheetComponent implements OnInit{

  interval : any;
  product : ProductDto
  variant: ProductVariantDto | null = null

  selectedOption : string | null = null

  constructor(private bottomSheetRef: MatBottomSheetRef<ProductDetailSheetComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public selection: any,
              public shoppingCartService : ShoppingcartService, private notificationService : NotificationService, public guestTracking : GuesttrackingService) {

    this.product = selection.product;
    this.variant = selection.variant;
  }


  ngOnInit(): void {
    this.initQuantity()
  }

  initQuantity() {
    if(this.product) {
      if (this.shoppingCartService.getQuantityOfProduct(this.product, this.variant, this.selectedOption) == 0) {
        if(this.guestTracking.guestTrackingEnabled && (this.guestTracking.trackingCode == null || this.guestTracking.trackingCode.length < 3)) {
          return;
        }
        this.updateCart(this.product, this.variant, this.selectedOption, 0, false);
      }
    }
  }

  decreaseAmount() {

    if(this.product == null) return;

    if(this.guestTracking.guestTrackingEnabled && this.guestTracking.trackingCode == null) {
      this.notificationService.showNotification("Gast-Nummer erforderlich")
      return;
    }

    this.shoppingCartService.decreaseQuantityOfProduct(this.product!, this.variant, this.selectedOption)

    if(this.shoppingCartService.getQuantityOfProduct(this.product!, this.variant, this.selectedOption) == 0) {
      this.shoppingCartService.removeProduct(this.product!);
      return
    }

    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.updateCart(this.product!, this.variant, this.selectedOption, null, true);
    }, 800);
  }

  increaseAmount() {

    if(this.product == null) return;

    if(this.guestTracking.guestTrackingEnabled && this.guestTracking.trackingCode == null) {
      this.notificationService.showNotification("Gast-Nummer erforderlich")
      return;
    }

    this.shoppingCartService.increaseQuantityOfProduct(this.product!)

    let quantity = this.shoppingCartService.getQuantityOfProduct(this.product!, this.variant, this.selectedOption);
    if(quantity == 0) {
      quantity = 1;
    }

    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.updateCart(this.product!, this.variant, this.selectedOption, quantity, true);
    }, 800);
  }

  getQuantity() : number {
    if(this.product != null) {
      return this.shoppingCartService.getQuantityOfProduct(this.product, this.variant, this.selectedOption)
    }
    return 0
  }

  private async updateCart(product: ProductDto, variant: ProductVariantDto| null, option: string | null, quantity : number | null, showToast : boolean) {

      this.shoppingCartService.update(product, variant, quantity, option, true).subscribe({
        next: res => {
          if(showToast) {

            let productNameParam : string = (variant && variant?.name) ? variant?.name + ' (' + product.name + ')' :  product.name;

            this.notificationService.showNotification('Du hast ' +
              (quantity == null ? this.shoppingCartService.getQuantityOfProduct(product, variant, option) : quantity) +  ' Stück ' + productNameParam + ' zu deiner Bestellung hinzugefügt. Schließe die Bestellung im Bestellungs-Reiter ab.');
          }
        },
        error: err => {

          if(err.error.code == "INVALID_GUEST_CODE") {
            this.notificationService.showNotification("Ungültiger Gase-Code. Bitte gib einen gültigen Gast-Code ein.")
          }
          else {
            this.notificationService.showNotification("Produkte konnte nicht bestellt werden")
          }
        }
      })
    }


  close(): void {
    this.bottomSheetRef.dismiss();
  }


  getProductName() {
    if(this.variant && this.variant.name) return this.variant.name + " (" + this.product.name + ")";
    return this.product.name;
  }

  getProductAmount() {
    if(this.variant) return this.variant.amountUnit;
    return this.product.amountUnit;
  }

  getProductUnit() {
    if(this.variant) return this.variant.unit;
    return this.product.unit;
  }

  getProductPrice() {
    if(this.variant) return this.variant.priceAmount;
    return this.product.priceAmount;
  }

  resetOption() {
    this.selectedOption = null
  }

  guestTrackingInputChanged($event: any) {
    this.guestTracking.trackingCode = $event.toLowerCase()
    if($event.length >= 3) {
      this.initQuantity();
    }
  }

  closeAndOpenShoppingCart() {
    this.bottomSheetRef.dismiss({
      openShoppingCart: true
    });
  }


}
