<div *ngFor="let section of sections" class="card" (click)="openSectionDetails(section)">
  <h1>{{ section.name }}</h1>
  <p style="color:red; margin-top: 1rem" *ngIf="section.cashinDemanded">Offener Bezahlwunsch</p>
</div>

<div *ngIf="!sections || sections.length == 0"  class="card">

  <p>Aktuell gibt es keine Bereiche</p>

</div>
