import {Component, Input, OnInit} from '@angular/core';
import {ShopnowOrderPositionDto, ShopnowOrderWithPositionsDto} from "../../model/order.model";
import {StoretableApiService} from "../../api/storetable-api.service";
import {StoreTableDto} from "../../model/product.model";

@Component({
  selector: 'app-table-open-order-list',
  templateUrl: './table-open-order-list.component.html',
  styleUrls: ['./table-open-order-list.component.scss']
})
export class TableOpenOrderListComponent implements OnInit{


  @Input()
  storeTable : StoreTableDto | null = null;


  orders: ShopnowOrderWithPositionsDto[] = []
  overallSum : number = 0


  constructor(private storeTableApiService: StoretableApiService) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.storeTableApiService.getOpenOrders(this.storeTable!.id).subscribe({
      next: res => {
        this.orders = res;
        this.overallSum = 0
        for(let o of res) {
          this.overallSum += o.sum;
        }
      }
    })
  }


  getProductName(item : ShopnowOrderPositionDto) {
    if(item.variant && item.variant.name) return item.variant.name + " (" + item.product.name + ")";
    return item.product.name;
  }

  getProductAmount(item : ShopnowOrderPositionDto) {
    if(item.variant) return item.variant.amountUnit;
    return item.product.amountUnit;
  }

  getProductUnit(item : ShopnowOrderPositionDto) {
    if(item.variant) return item.variant.unit;
    return item.product.unit;
  }

}
