import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {StoreSectionDto, StoreTableDto} from "../model/product.model";
import {Observable} from "rxjs";
import {
  ShopnowOrderWithPositionsDto,
  ShopnowStaffMoveOrdersInputDto,
  StaffMarkCashinInputDto
} from "../model/order.model";


@Injectable({
  providedIn: 'root'
})
export class StoreSectionApiService {

  constructor(private http: HttpClient) { }

  getById(id : number) : Observable<StoreSectionDto>  {
    return this.http.get<StoreTableDto>(environment.baseurl + "v1/storesection/" + id)
  }

  getAll() : Observable<StoreSectionDto[]>  {
    return this.http.get<StoreTableDto[]>(environment.baseurl + "v1/storesection")
  }

}
