import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ShoppingCartDto, UpdateShoppingCartInputDto} from "../model/shoppingcart.model";
import {ShopnowOrderDto} from "../model/order.model";


@Injectable({
  providedIn: 'root'
})
export class ShoppingCartApiService {

  constructor(private http: HttpClient) { }

  getShoppingCart()  {
    return this.http.get<ShoppingCartDto>(environment.baseurl + "v1/shopnow/shoppingcart")
  }

  updateShoppingCart(updateShoppingCartInputDto : UpdateShoppingCartInputDto)  {
    return this.http.put<ShoppingCartDto>(environment.baseurl + "v1/shopnow/shoppingcart", updateShoppingCartInputDto)
  }

  getQuantity(productId: number)  {
    return this.http.get<Number>(environment.baseurl + "v1/shopnow/shoppingcart/quantity/" + productId)
  }

  removeItem(id : number)  {
    return this.http.delete<ShoppingCartDto>(environment.baseurl + "v1/shopnow/shoppingcart/" + id)
  }

  order(tableCode: string)  {
    return this.http.put<ShopnowOrderDto>(environment.baseurl + "v1/shopnow/shoppingcart/order/" + tableCode, {})
  }



}
