import {Component, OnInit} from '@angular/core';
import {StoreSectionDto, StoreTableDto} from "../../model/product.model";
import {StoretableApiService} from "../../api/storetable-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StoreSectionApiService} from "../../api/storesection-api.service";
import {GuesttrackingService} from "../../services/guesttracking.service";

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent implements OnInit{

  sections : StoreSectionDto[] = []

  constructor(private storeSectionApiSerice: StoreSectionApiService, private route: ActivatedRoute, private router : Router) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {


    this.storeSectionApiSerice.getAll().subscribe({
      next: res => {
        this.sections = res;
      },
      error: err => {

      }

    })

  }

  openSectionDetails(section: StoreSectionDto) {
    this.router.navigateByUrl("/staff/table-list/" + section.id)
  }

}
