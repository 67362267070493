import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {matchValidator} from "../../validators/form-validators";
import {DateAdapter} from "@angular/material/core";
import {SignupPersonalInputDto, SignupPhoneVerificationInputDto} from "../../model/signup/signup.model.spec";
import {SignupService} from "../../services/signup.service";
import {NotificationService} from "../../services/notification.service";
import {DateTime} from "luxon";
import {Router} from "@angular/router";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  personalForm!: UntypedFormGroup;

  current = 0;
  phoneVerified: boolean = false;

  phone: string = "";
  phonePrefix: string = "";

  constructor(private fb: UntypedFormBuilder, private dateAdapter: DateAdapter<Date>, public signupService: SignupService,
              private notificationService: NotificationService, private router: Router) {
    this.dateAdapter.setLocale('de');
  }


  ngOnInit(): void {
    this.personalForm = this.fb.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      mail: [null, [Validators.required, Validators.email]],
      phonePrefix: [null, [Validators.required]],
      phone: [null, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(12)]],
      birthday: [null, Validators.required],
      password: [null, [Validators.required,
        Validators.minLength(10),
        Validators.maxLength(100),
        matchValidator('confirmPassword', true)]],
      passwordrepeat: [null, [Validators.required,
        matchValidator('password')]]
    });

    this.personalForm.controls['phonePrefix'].patchValue("+43")
  }

  public submitPersonalForm(): void {
    if (this.personalForm.valid) {

      var signupPersonalInputDto = <SignupPersonalInputDto>this.personalForm.value;
      signupPersonalInputDto.birthday = DateTime.fromJSDate(new Date(this.personalForm.controls['birthday'].value)).toISODate()!

      this.signupService.start(signupPersonalInputDto).subscribe(
        {
          next: res => {
            this.phone = res.phone
            this.phonePrefix = res.phonePrefix
            this.next()
          },
          error: err => {
            this.notificationService.showNotification("Es ist ein Fehler aufgetreten")
          }
        }
      )
      ;
    } else {
      Object.values(this.personalForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  next()
    :
    void {
    this.current += 1;
  }

  resendPhoneCode() {
    this.signupService.resendPhone().subscribe({
      next: () => {
        this.notificationService.showNotification("Wir haben dir eine SMS gesendet")
      }
    })
  }

  continuePhoneVerification() {
    if (this.phoneVerified) {
      this.next();
    }
  }


  onCodeCompleted(event : string) {
    var verificationInputDto: SignupPhoneVerificationInputDto = {
      phone: this.phone,
      phonePrefix: this.phonePrefix,
      verificationCode: event
    }

    this.signupService.verifyPhone(verificationInputDto).subscribe({
      next: res => {
        this.notificationService.showNotification("Deine Telefonnummer wurde bestätigt")
        this.phoneVerified = true;
        this.finish();
      },
      error: () => {
        this.notificationService.showNotification("Deine Telefonnummer konnte nicht bestätigt werden. Bitte versuche es nochmal")
        this.phoneVerified = false;
      }
    });
  }

  finish() {
    this.signupService.finish()
    this.router.navigateByUrl("/staff")
  }
}
