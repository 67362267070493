import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DateAdapter} from "@angular/material/core";
import {SignupService} from "../../services/signup.service";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {matchValidator} from "../../validators/form-validators";
import {SignupUserExistingAppDataInputDto} from "../../model/signup/signup.model.spec";
import {LoginCodeResultDto} from "../../model/auth/logininput.model";
import {AuthService} from "../../services/auth.service";
import * as uuid from 'uuid';

@Component({
  selector: 'app-signup-applogin',
  templateUrl: './signup-applogin.component.html',
  styleUrls: ['./signup-applogin.component.scss']
})
export class SignupApploginComponent implements OnInit {

  personalForm!: UntypedFormGroup;
  passwordForm!: UntypedFormGroup;

  current = 0;
  phoneVerified: boolean = false;

  mail: string = ''
  deviceId: string = ''
  codeResult: LoginCodeResultDto | null = null;

  constructor(private fb: UntypedFormBuilder, private dateAdapter: DateAdapter<Date>, public signupService: SignupService,
              private notificationService: NotificationService, private router: Router, private authService: AuthService) {
    this.dateAdapter.setLocale('de');
  }


  ngOnInit(): void {
    this.personalForm = this.fb.group({
      mail: [null, [Validators.required, Validators.email]],
    });

    this.passwordForm = this.fb.group({
      password: [null, [Validators.required,
        Validators.minLength(10),
        Validators.maxLength(100),
        matchValidator('confirmPassword', true)]],
      passwordrepeat: [null, [Validators.required,
        matchValidator('password')]]
    });
  }

  startLoginFlow() {

    this.signupService.startWithExistingApp();
    this.mail = this.personalForm.controls["mail"].value
    this.deviceId = uuid.v4().toString()

    this.authService.startLoginFlow({
      deviceId: this.deviceId,
      email: this.mail
    }).subscribe({
      next: res => {
        this.next();
        this.codeResult = res;
      },
      error: err => {
        if (err.error.code == 'USER_NOT_SIGNED_UP') {
          this.notificationService.showNotification("User mit dieser E-Mail Adresse existiert nicht")
        }
        else {
          this.notificationService.showNotification("Es konnte nicht mit der Registrierung gestartet werden")
        }
      }
    })
  }

  next(): void {
    this.current += 1;
  }

  resendPhoneCode() {
    this.signupService.resendPhone().subscribe({
      next: () => {
        this.notificationService.showNotification("Wir haben dir eine SMS gesendet")
      }
    })
  }

  continuePhoneVerification() {
    if (this.phoneVerified) {
      this.next();
    }
  }


  onCodeCompleted(event: string) {

    this.authService.loginWithFlow({
      deviceId: this.deviceId,
      email: this.mail,
      totpToken: event,
      shopnow: true
    }).subscribe({
      next: success => {
        if (success) {
          this.authService.getLoggedIn().subscribe({
            next: res => {
              this.next();
            }
          })
        } else {
          console.log("Logincode stimmt nicht überein 1")
          this.notificationService.showNotification("Logincode stimmt nicht überein")
        }
      },
      error: err => {
        console.log("Logincode stimmt nicht überein")
        this.notificationService.showNotification("Logincode stimmt nicht überein")
      }
    })

  }

  public submitPasswordForm(): void {
    if (this.passwordForm.valid) {

      var signupStoreInput = <SignupUserExistingAppDataInputDto>this.passwordForm.value;

      this.signupService.signupUserExistingApp(signupStoreInput).subscribe({
          next: res => {
            this.finish();
          },
          error: err => {
            this.notificationService.showNotification("Das Passwort konnte nicht hinterlegt werden")
          }
        }
      )
      ;
    } else {
      Object.values(this.passwordForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  finish() {
    this.signupService.finish()
    this.router.navigateByUrl("/staff")
  }
}
