<div>
  <h2>Service-Login</h2>
  <form [formGroup]="validateForm" (ngSubmit)="submit()">
    <p>
      <mat-form-field>
        <input type="text" matInput placeholder="E-Mail" formControlName="email">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field>
        <input type="password" matInput placeholder="Password" formControlName="password">
      </mat-form-field>
    </p>


    <div class="button">
      <button type="submit" mat-button>Anmelden</button>
    </div>

  </form>

  <div class="button">
    <button mat-button (click)="openSignup()">Registrierung durchführen</button>
  </div>
  <div class="button">
    <button mat-button (click)="openSignupWithAppLogin()">Registrierung mit App-Login</button>
  </div>

</div>
