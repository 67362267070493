import {Injectable} from '@angular/core';
import {
  PlaceStaffOrderDto,
  ShoppingCartDto,
  ShoppingCartProductItemDto,
  StaffOrderInputDto,
  UpdateShoppingCartInputDto
} from "../model/shoppingcart.model";
import {Observable, Subject} from "rxjs";
import {ShoppingCartApiService} from "../api/shoppingcart-api.service";
import {CategoryWithProductItemsDto, ProductDto, ProductVariantDto} from "../model/product.model";
import {NotificationService} from "./notification.service";
import {ShopnowOrderDto} from "../model/order.model";
import {OrderApiService} from "../api/order-api.service";

@Injectable({
  providedIn: 'root'
})
export class StaffOrderService {

  orders : StaffOrderInputDto[] = []

  public ordersChanged = new Subject<number>();

  constructor(private orderApiService: OrderApiService, private notificationService : NotificationService) {
  }

  public getQuantitySum() {
    let result = 0;

    for(let item of this.orders) {
      result += item.quantity;
    }

    return result;
  }

  public initQuantityOfProduct(items : CategoryWithProductItemsDto[] ) {

    if(this.orders && this.orders.length > 0) return;

    for(let cat of items) {
      for(let product of cat.products) {
        this.orders.push({
          product: product,
          quantity: 0,
          variant: null,
          option: null
        })

        for(let variant of product.variants) {
          this.orders.push({
            product: product,
            variant: variant,
            quantity: 0,
            option: null
          })
        }
      }
    }
  }

  public remove(product: ProductDto, variant: ProductVariantDto | null, option : string | null) {
    for (var item of this.orders) {
      if(variant != null && item.variant != null && item.variant.id === variant.id && option != null && item.option != null && item.option == option) item.quantity = 0
      else if(variant != null && item.variant != null && item.variant.id === variant.id && option == null && item.option == null) item.quantity = 0
      else if (variant == null && item.product.id === product.id && item.variant === null && option != null && item.option != null && item.option == option) item.quantity = 0
      else if (variant == null && option == null && item.option == null && item.product.id === product.id && item.variant === null) item.quantity = 0
    }
  }

  public update(product: ProductDto, variant: ProductVariantDto | null, option : string, addQuantity : number) {
    this.orders.push({
      product: product,
      variant: variant,
      quantity: addQuantity,
      option: option
    })
  }

  public getQuantityOfProduct(product: ProductDto, variant: ProductVariantDto | null): number {

    let result = 0

    for (var item of this.orders) {
      if(variant != null && item.variant != null && item.variant.id === variant.id) result += item.quantity;
      else if (variant == null && item.product.id === product.id && item.variant === null) result += item.quantity
    }
    return result;
  }

  public increaseQuantityOfProduct(product: ProductDto, variant: ProductVariantDto | null) {
    for (var item of this.orders) {
      if(variant != null && item.variant != null && item.variant.id === variant.id && item.option == null)  item.quantity++;
      else if (variant == null && item.product.id === product.id && item.variant == null && item.option == null) item.quantity++
    }

  }


  public decreaseQuantityOfProduct(product: ProductDto, variant : ProductVariantDto | null) {

    let current = this.getQuantityOfProduct(product, variant);
    if (current <= 0) return;

    for (var item of this.orders) {
      if(variant != null && item.variant && item.variant.id === variant.id && item.option == null)  item.quantity--;
      else if (variant == null && item.product.id === product.id && item.variant == null && item.option == null) item.quantity--
    }
  }

  public order(placeOrder: PlaceStaffOrderDto): Observable<ShopnowOrderDto> {


    const observable = new Observable<ShopnowOrderDto>(observer => {
      this.orderApiService.placeStaffOrder(placeOrder).subscribe({
        next: res => {
          this.reset();
          this.notify();
          this.notificationService.showNotification("Bestellung erfolgreich gesendet")
          observer.next(res);
        },
        error: err => {
          //this.notficationService.handleRequestError(err)
          observer.error(err)
        }
      })
    });

    return observable;
  }

  private reset() {
    this.orders = []
  }


  private notify() {
    this.ordersChanged.next(this.orders.length)
  }

}
