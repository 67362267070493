import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {
  LoginCodeResultDto,
  LoginInputDto, LoginWithFlowInputDto,
  RefreshLoginInputDto,
  StartLoginFlowInputDto
} from "../model/auth/logininput.model";
import {TokenResultDto} from "../model/auth/tokenresult.model";



@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private http: HttpClient) { }

  login(loginInput : LoginInputDto) {
    loginInput.shopnow = true
    return this.http.post<TokenResultDto>(environment.baseurl + "v1/auth/login", loginInput)
  }

  refreshLogin(refreshInput: RefreshLoginInputDto) {
    refreshInput.shopnow = true
    return this.http.post<TokenResultDto>(environment.baseurl + "v1/auth/refresh", refreshInput)
  }

  startLoginFlow(startLoginFlow: StartLoginFlowInputDto) {
    return this.http.post<LoginCodeResultDto>(environment.baseurl + "v1/auth/flow/start", startLoginFlow)
  }

  loginWithFlow(loginWithFlow: LoginWithFlowInputDto) {
    return this.http.post<TokenResultDto>(environment.baseurl + "v1/auth/flow/login", loginWithFlow)
  }



}
