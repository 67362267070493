import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {ShopnowSessionService} from "../services/shopnow-session.service";
import {throwError} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class SessionInterceptorService implements HttpInterceptor {


  constructor(private shopnowSessionService: ShopnowSessionService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const sessionId = this.shopnowSessionService.getShopSessionId();


    if (sessionId) {
      // If we have a token, we set it to the header
      request = request.clone({
        setHeaders: {'shopnow-session-id': `${sessionId}`}
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 500) {
            //this.presentToast('bottom')
          }
        }
        return throwError(err);
      })
    )


  }


}
