import {Pipe, PipeTransform} from "@angular/core";


@Pipe({
  name: 'specialamount',
  pure: false,
})
export class SpecialAmountPipe implements PipeTransform {


  transform(amount: number | undefined): string {

    if(amount) {
      if(amount === 0.125) return "1/8"
      else if(amount === 0.0625) return "1/16"
      else if(amount === 0.062) return "1/16"
      else return amount.toString();
    }
    return "";
  }




}
