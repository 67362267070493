import {Component, Inject, OnInit} from '@angular/core';
import {StaffOrderService} from "../../services/stafforder.service";
import {PlaceStaffOrderDto, StaffOrderInputDto} from "../../model/shoppingcart.model";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";
import {GuesttrackingService} from "../../services/guesttracking.service";

@Component({
  selector: 'app-order-detail-sheet',
  templateUrl: './order-detail-sheet.component.html',
  styleUrls: ['./order-detail-sheet.component.scss']
})
export class OrderDetailSheetComponent implements OnInit{

  orders : StaffOrderInputDto[] = []
  storeTableId : number | null = null
  sectionId : number | null = null;

  orderInProcess : boolean = false;

  guestTrackingCode : string | null = null;

  constructor(private staffOrderService : StaffOrderService, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private router : Router,
              private notification : NotificationService, private bottomSheetRef: MatBottomSheetRef<OrderDetailSheetComponent>,
              public guestTracking : GuesttrackingService) {
    this.storeTableId = data.storeTableInputParam
    this.sectionId = data.sectionInputParam
  }

  ngOnInit() {
    this.orders = this.staffOrderService.orders.filter(i => i.quantity > 0);
  }

  removeItem(orderItem: StaffOrderInputDto) {
    this.staffOrderService.remove(orderItem.product, orderItem.variant, orderItem.option)
    this.orders = this.staffOrderService.orders.filter(i => i.quantity > 0);
  }

  sendOrder() {

    if(this.guestTracking.guestTrackingEnabled && this.guestTrackingCode == null) {
      this.notification.showNotification("Gast-Code erforderlich")
      return;
    }

    this.orderInProcess = true;

    let input : PlaceStaffOrderDto = {
      storeTableId: this.storeTableId!,
      items: [],
      guestTrackingCode: this.guestTrackingCode
    }

    for(let order of this.orders) {
      input.items.push({
        option: order.option,
        productId: order.product.id,
        quantity: order.quantity,
        variantId: order.variant != null ? order.variant.id : null
      })
    }

    this.staffOrderService.order(input).subscribe({
      // nvagiate back after order
      next: res => {
          this.bottomSheetRef.dismiss()
          this.notification.showNotification("Bestellung erfolgreich gesendet")
          this.router.navigateByUrl("/staff/table-list/" + this.sectionId)
          this.orderInProcess = false;
      },
      error: err => {

        if(err.error.code == "INVALID_GUEST_CODE") {
          this.notification.showNotification("Ungültiger Gase-Code. Bitte gib einen gültigen Gast-Code ein.")
        }
        else {
          this.notification.showNotification("Bestellung konnte nicht durchgeführt werden")
        }
        this.orderInProcess = false;
      }
    })
  }

}
