import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {LoginInputDto} from "../../model/auth/logininput.model";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

  validateForm!: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router, private notification : NotificationService) {
  }


  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }



  submit() {
    if (this.validateForm.valid) {
      var loginInput = <LoginInputDto> this.validateForm.value;
      loginInput.shopnow = true
      this.authService.login(loginInput).subscribe(bool => {
        if(bool) {
          this.router.navigateByUrl("/staff")
        }
        else {
          this.notification.showNotification("Logindaten inkorrekt")
        }
      })
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }


  openSignup() {
    this.router.navigateByUrl("/staff/signup");
  }

  openSignupWithAppLogin() {
    this.router.navigateByUrl("/staff/signup-applogin");
  }
}
