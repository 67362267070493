import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {StoreTableDto} from "../model/product.model";
import {Observable} from "rxjs";
import {
  ShopnowOrderWithPositionsDto,
  ShopnowStaffMoveOrdersInputDto, StaffCancelInputDto,
  StaffMarkCashinInputDto
} from "../model/order.model";


@Injectable({
  providedIn: 'root'
})
export class StoretableApiService {

  constructor(private http: HttpClient) { }

  get(tableCode: string) : Observable<StoreTableDto>  {
    return this.http.get<StoreTableDto>(environment.baseurl + "v1/storetable/public/" +  tableCode)
  }

  getById(id : number) : Observable<StoreTableDto>  {
    return this.http.get<StoreTableDto>(environment.baseurl + "v1/storetable/" + id)
  }

  getAll() : Observable<StoreTableDto[]>  {
    return this.http.get<StoreTableDto[]>(environment.baseurl + "v1/storetable/active")
  }

  getAllForSection(sectionId: number) : Observable<StoreTableDto[]>  {
    return this.http.get<StoreTableDto[]>(environment.baseurl + "v1/storetable/active/" + sectionId)
  }

  getOpenOrders(id : number) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.get<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storetable/" + id + "/openorders")
  }

  getOpenOrdersForCashin(id : number) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.get<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storetable/" + id + "/openorders/cashin")
  }

  markCashin(input : StaffMarkCashinInputDto) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.post<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storetable/markcashin", input)
  }

  cancelPositions(input : StaffCancelInputDto) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.post<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storetable/cancelpositions", input)
  }

  moveTable(input : ShopnowStaffMoveOrdersInputDto) : Observable<ShopnowOrderWithPositionsDto[]>  {
    return this.http.post<ShopnowOrderWithPositionsDto[]>(environment.baseurl + "v1/storetable/movetable", input)
  }


}
