import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StaffComponent} from './staff.component';
import {StaffRoutingModule} from "./staff-routing.module";
import {TableListComponent} from './table-list/table-list.component';
import {TableDetailComponent} from './table-detail/table-detail.component';
import {TableOrderComponent} from './table-order/table-order.component';
import {TableCashinComponent} from './table-cashin/table-cashin.component';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {PublicModule} from "../public/public.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {OrderDetailSheetComponent} from './order-detail-sheet/order-detail-sheet.component';
import {OrderItemOptionComponent} from './order-item-option/order-item-option.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {TableOpenOrderListComponent} from './table-open-order-list/table-open-order-list.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {SignupComponent} from './signup/signup.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {CodeInputModule} from "angular-code-input";
import {TableMoveComponent} from "./table-move/table-move.component";
import { SectionListComponent } from './section-list/section-list.component';
import {UtilModule} from "../util/util.module";
import {MatChipsModule} from "@angular/material/chips";
import {MatDialogModule} from "@angular/material/dialog";
import { GuestCashinComponent } from './guest-cashin/guest-cashin.component';
import { SignupApploginComponent } from './signup-applogin/signup-applogin.component';


@NgModule({
  declarations: [
    StaffComponent,
    TableListComponent,
    TableDetailComponent,
    TableOrderComponent,
    TableCashinComponent,
    OrderDetailSheetComponent,
    OrderItemOptionComponent,
    TableOpenOrderListComponent,
    TableMoveComponent,
    SignupComponent,
    SectionListComponent,
    GuestCashinComponent,
    SignupApploginComponent
  ],
  imports: [
    CommonModule,
    StaffRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatGridListModule,
    MatToolbarModule,
    PublicModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    UtilModule,
    MatChipsModule,
    CodeInputModule,
    MatDialogModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCodeHidden: false
    }),
  ],
  exports: [StaffComponent]
})
export class StaffModule {
}
