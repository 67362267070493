<div>
  <mat-grid-list cols=3 rowHeight="2:1">
    <mat-grid-tile colspan="2">
      <span>{{ getQuantitySum() }} Produkte in Bestellung</span>
    </mat-grid-tile>
    <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
      <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="openOrderDetails()">
        <mat-icon>send</mat-icon>
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div>
  <mat-chip-listbox selectable (change)="categorySelected($event)">
    <mat-chip-option *ngFor="let cat of categories" [value]="cat">{{cat.name}}</mat-chip-option>
  </mat-chip-listbox>
</div>

<div *ngFor="let category of filteredCategories">

  <h4 style="margin-top: 0.5rem" *ngIf="category.products && category.products.length > 0">{{ category.name }}</h4>

  <div *ngFor="let product of category.products" class="card">

    <mat-grid-list cols=4 rowHeight="2:1">

      <mat-grid-tile colspan="2">
        <span>{{ product.name }}</span>
      </mat-grid-tile>
      <mat-grid-tile>
        {{ product.amountUnit | specialamount}} {{ 'enum.unit.' + product.unit | translate }}
      </mat-grid-tile>
      <mat-grid-tile>
        <span>€ {{ product.priceAmount | number: '1.2-2':'de' }}</span>
      </mat-grid-tile>


      <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
        <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="decreaseAmount(product, null)">
          <mat-icon>remove</mat-icon>
        </button>
      </mat-grid-tile>
      <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
        <span style="font-size: 1rem">{{ getQuantity(product, null) }}</span>
      </mat-grid-tile>
      <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
        <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="increaseAmount(product, null)">
          <mat-icon>add</mat-icon>
        </button>
      </mat-grid-tile>
      <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
        <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="openAddOption(product, null)">
          <mat-icon>rate_review</mat-icon>
        </button>
      </mat-grid-tile>


    </mat-grid-list>

    <div *ngFor="let variant of product.variants" class="variantcard">
      <mat-grid-list cols=4 rowHeight="2:1">

        <mat-grid-tile colspan="2">
          <span style="font-size: 0.8rem">{{ variant.name }}</span>
        </mat-grid-tile>
        <mat-grid-tile>
          {{ variant.amountUnit | specialamount}} {{ 'enum.unit.' + variant.unit | translate }}
        </mat-grid-tile>
        <mat-grid-tile>
          <span>€ {{ variant.priceAmount | number: '1.2-2':'de' }}</span>
        </mat-grid-tile>

        <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
          <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="decreaseAmount(product, variant)">
            <mat-icon>remove</mat-icon>
          </button>
        </mat-grid-tile>
        <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
          <span style="font-size: 1rem">{{ getQuantity(product, variant) }}</span>
        </mat-grid-tile>
        <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
          <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="increaseAmount(product, variant)">
            <mat-icon>add</mat-icon>
          </button>
        </mat-grid-tile>
        <mat-grid-tile style="margin-top: 1rem; margin-bottom: 1rem">
          <button mat-mini-fab extended style="background: transparent; box-shadow: none" (click)="openAddOption(product, variant)">
            <mat-icon>rate_review</mat-icon>
          </button>
        </mat-grid-tile>

      </mat-grid-list>
    </div>


  </div>

</div>


