<mat-grid-list cols="3" rowHeight="2:1">


  <mat-grid-tile colspan="2">
    <p style="font-size: 0.7rem">Hier kannst du das Produkt in deine Bestellung aufnehmen</p>
  </mat-grid-tile>
  <mat-grid-tile>
    <span style="align-content: flex-end"><mat-icon style="font-size: 1.4rem" (click)="close()">close</mat-icon></span>
  </mat-grid-tile>

  <mat-grid-tile>
    <mat-list-item>{{ getProductAmount() }} {{ 'enum.unit.' + getProductUnit() | translate }}</mat-list-item>
  </mat-grid-tile>
  <mat-grid-tile>
    {{ getProductName() }}
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-list-item>€ {{ getProductPrice() | number: '1.2-2':'de' }}</mat-list-item>
  </mat-grid-tile>

  <mat-grid-tile colspan="3" *ngIf="product.options && product.options.length > 0">
    <mat-form-field appearance="fill">
      <mat-label>Auswahl</mat-label>
      <mat-select [(value)]="selectedOption" style="width: 100%">
        <mat-option *ngFor="let option of product.options" [value]="option.name">
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span style="margin-left: 1rem; margin-bottom: 1rem; align-self: center" class="material-icons"
          (click)="resetOption()">cancel</span>
  </mat-grid-tile>

  <mat-grid-tile colspan="3" *ngIf="product.description">
    {{ product.description }}
  </mat-grid-tile>

  <mat-grid-tile colspan="3" *ngIf="product.ingredients">
    {{ product.ingredients }}
  </mat-grid-tile>

  <mat-grid-tile>
    <p>Allergene:</p>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <div style="overflow: scroll; display: flex; flex-direction: column; justify-content: space-between; width: 100%; height: 100%;">
      <mat-chip-set>
        <mat-chip style="background: beige; font-size: 0.7rem"
                  *ngFor="let allergen of product.allergens">{{ 'enum.allergen.' + allergen | translate }}
        </mat-chip>
      </mat-chip-set>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <p>Diettypen:</p>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <div style="overflow: scroll; display: flex; flex-direction: column; justify-content: space-between; width: 100%; height: 100%;">
      <mat-chip-set>
        <mat-chip style="background: beige; font-size: 0.7rem"
                  *ngFor="let diettype of product.diettypes">{{ 'enum.diettype.' + diettype | translate }}
        </mat-chip>
      </mat-chip-set>
    </div>
  </mat-grid-tile>


  <mat-grid-tile colspan="3" *ngIf="guestTracking.guestTrackingEnabled">
    <mat-form-field style="margin-top: 0.5rem">
      <mat-label>Gast-Nummer</mat-label>
      <input matInput maxlength="3" placeholder="..." [(ngModel)]="guestTracking.trackingCode"
             (ngModelChange)="guestTrackingInputChanged($event)">
    </mat-form-field>
  </mat-grid-tile>

</mat-grid-list>
<mat-grid-list cols="5">
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile>
    <button mat-mini-fab extended style="background: transparent; margin-top: 0.2rem;" (click)="decreaseAmount()">
      <mat-icon>remove</mat-icon>
    </button>
  </mat-grid-tile>
  <mat-grid-tile>
    <span style="font-size: 1rem">{{ getQuantity() }}</span>
  </mat-grid-tile>
  <mat-grid-tile>
    <button mat-mini-fab extended style="background: transparent;" (click)="increaseAmount()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-grid-tile>
  <mat-grid-tile></mat-grid-tile>
  <mat-grid-tile colspan="5">
    <button mat-fab extended style="background: transparent;" (click)="closeAndOpenShoppingCart()">
      <mat-icon>send</mat-icon>Fortfahren
    </button>
  </mat-grid-tile>
</mat-grid-list>


