<div>

  <div *ngFor="let order of orders" class="card">

    <span>Bestellt am {{order.createdAt | date : 'dd.MM.yyyy HH:mm' : '' : 'de'}} /  Status: {{ 'enum.shopnoworderstate.' + order.state | translate}}</span>

    <div *ngFor="let orderPosition of order.positions" class="variantcard">

      <mat-grid-list cols="6" rowHeight="1:1">

        <mat-grid-tile colspan="4">
          {{ orderPosition.quantity }} x {{ getProductName(orderPosition) }}
        </mat-grid-tile>
        <mat-grid-tile>
          {{getProductAmount(orderPosition)}} {{'enum.unit.' + getProductUnit(orderPosition) | translate}}
        </mat-grid-tile>
        <mat-grid-tile>
          <span>€ {{orderPosition.positionSum | number: '1.2-2':'de'}}</span>
        </mat-grid-tile>

      </mat-grid-list>

    </div>


    <mat-grid-list cols="6" rowHeight="1:1">
      <mat-grid-tile colspan="3"><span style="font-size: 0.7rem; padding: 0.5rem">{{order.tableName}}</span></mat-grid-tile>
      <mat-grid-tile colspan="3">
        <span style="font-size: 0.9rem; padding: 0.5rem">Summe: € {{ order.sum | number: '1.2-2':'de'}}</span>
      </mat-grid-tile>

    </mat-grid-list>

    <span style="font-size: 0.6rem">Bestellnr: {{order.orderNumber}}</span>

  </div>


  <mat-grid-list cols="6" rowHeight="1:1" *ngIf="overallSum > 0">
    <mat-grid-tile colspan="3"></mat-grid-tile>
    <mat-grid-tile colspan="3">
      <span style="font-size: 1.1rem">Summe: € {{ overallSum | number: '1.2-2':'de'}}</span>
    </mat-grid-tile>

    <mat-grid-tile colspan="6">
      <button mat-fab extended style="background: transparent; margin: 1.5rem" (click)="demandCashin()"><mat-icon>wallet</mat-icon>Bezahlen</button>
    </mat-grid-tile>

  </mat-grid-list>


</div>

<div *ngIf="!orders || orders.length == 0"  class="card">

  <p>Es gibt noch keine Bestellungen</p>

</div>
