<mat-toolbar>
  <button mat-fab extended style="background: transparent; margin-top: 0.2rem; align-self: center" (click)="back()"><mat-icon>arrow_back</mat-icon></button>
  <span style="font-size:0.9rem; margin-left: 0.5rem">{{ storeName }} / {{section?.name}} / {{userName}}</span>
</mat-toolbar>


<div *ngFor="let table of tables" class="card" (click)="openTableDetails(table)">
  <h1>{{ table.name }}</h1>
  <p style="color:orange; margin-top: 1rem" *ngIf="table.unpaidOrders">Tisch hat offene Bestellungen</p>
  <p style="color:green; margin-top: 1rem" *ngIf="!table.unpaidOrders">Tisch ist frei</p>
  <p style="color:red; margin-top: 1rem" *ngIf="table.cashinDemanded">Tisch möchte bezahlen</p>
</div>

<div *ngIf="!tables || tables.length == 0"  class="card">

  <p>Aktuell gibt es keine Tische in diesem Bereich</p>

</div>
