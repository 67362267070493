import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {StoreTableDto} from "../../model/product.model";
import {ShopnowOrderPositionDto, ShopnowOrderWithPositionsDto, ShopnowPaymentMethod} from "../../model/order.model";
import {StoretableApiService} from "../../api/storetable-api.service";
import {NotificationService} from "../../services/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-table-cashin',
  templateUrl: './table-cashin.component.html',
  styleUrls: ['./table-cashin.component.scss']
})
export class TableCashinComponent implements OnInit {


  @Input()
  storeTable: StoreTableDto | null = null;

  @ViewChild('cancelConfirmationDialog') cancelConfirmationDialog: TemplateRef<any> | undefined;


  orders: ShopnowOrderWithPositionsDto[] = []

  cashinMarkedSum : number = 0
  markedPositions: number[] = []
  markedIndexPos : string[] = []
  sumWithTip: number | null = null

  selectedPaymentMethod : ShopnowPaymentMethod | null = null


  constructor(private storeTableApiService: StoretableApiService, private notification: NotificationService, private matDialog : MatDialog) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.storeTableApiService.getOpenOrdersForCashin(this.storeTable!.id).subscribe({
      next: res => {
        this.orders = res;
      }
    })
  }


  getProductName(item: ShopnowOrderPositionDto) {
    if (item.variant && item.variant.name) return item.variant.name + " (" + item.product.name + ")";
    return item.product.name;
  }

  getProductAmount(item: ShopnowOrderPositionDto) {
    if (item.variant) return item.variant.amountUnit;
    return item.product.amountUnit;
  }

  getProductUnit(item: ShopnowOrderPositionDto) {
    if (item.variant) return item.variant.unit;
    return item.product.unit;
  }

  markCheck(event: any, orderPosition: ShopnowOrderPositionDto, outerIndex: number, index: number) {

    console.log("mark check " + event.checked + " with key " + outerIndex + "_" + index)

    if(event.checked) {
      if(orderPosition.id) {
        this.cashinMarkedSum += orderPosition.variant != null ? orderPosition.variant.priceAmount : orderPosition.product.priceAmount
        this.markedPositions.push(orderPosition.id)
        this.markedIndexPos.push(outerIndex + "_" + index)
      }
    }
    else {
      if(orderPosition.id) {
        this.cashinMarkedSum -= orderPosition.variant != null ? orderPosition.variant.priceAmount : orderPosition.product.priceAmount
        this.markedPositions.splice(this.markedPositions.lastIndexOf(orderPosition.id), 1)
        this.markedIndexPos.splice(this.markedIndexPos.lastIndexOf(outerIndex + "_" + index), 1)
      }
    }

    console.log(this.markedIndexPos)
  }

  getProductPrice(orderPosition: ShopnowOrderPositionDto) {
    if (orderPosition.variant) return orderPosition.variant.priceAmount;
    return orderPosition.product.priceAmount;
  }

  markCashin() {

    if(!this.selectedPaymentMethod) {
      this.notification.showNotification("Zahlungsart wählen");
      return;
    }

    this.storeTableApiService.markCashin({
       storeTableId: this.storeTable!.id,
      cashedInOrderPositions: this.markedPositions,
      paymentMethod: this.selectedPaymentMethod!,
      sumWithTip: this.sumWithTip
    }).subscribe({
      next: res => {
        this.load()
        this.markedPositions = []
        this.markedIndexPos = []
        this.cashinMarkedSum = 0
        this.selectedPaymentMethod = null
        this.sumWithTip = null
        this.markedIndexPos = []
      }
    })
  }

  openCancelConfirmation() {
    this.matDialog.open(this.cancelConfirmationDialog!);
  }

  cancelPositions() {
    this.storeTableApiService.cancelPositions({
      storeTableId: this.storeTable!.id,
      cancelledOrderPositions: this.markedPositions,
    }).subscribe({
      next: res => {
        this.load()
        this.markedPositions = []
        this.markedIndexPos = []
        this.cashinMarkedSum = 0
        this.selectedPaymentMethod = null
      }
    })
  }

  protected readonly ShopnowPaymentMethod = ShopnowPaymentMethod;

  markAll($event: MatCheckboxChange) {
    if($event.checked) {

      let outerIndex = 0;

      for(let o of this.orders) {
        let index = 0;
        for(let p of o.positions) {
          if(p.id && this.markedIndexPos.indexOf(outerIndex + "_" + index) < 0) {
            this.markedPositions.push(p.id);
            this.markedIndexPos.push(outerIndex + "_" + index)
            this.cashinMarkedSum += p.variant != null ? p.variant.priceAmount : p.product.priceAmount
          }

          index++;
        }

        outerIndex++;
      }
    }
    else if(!$event.checked) {
      this.markedPositions = []
      this.markedIndexPos = []
      this.cashinMarkedSum = 0
    }
  }

  isChecked(outerIndex: number, index : number) {
    return this.markedIndexPos.indexOf(outerIndex  + "_" + index) >= 0;
  }
}
