import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {StorefeedApiService} from "../../api/storefeed-api.service";
import {ActivatedRoute} from "@angular/router";
import {CategoryDto} from "../../model/product.model";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit{

  categories : CategoryDto[] = []

  @Output()
  selectedCategory  = new EventEmitter<CategoryDto>();

  constructor(private storefeedApiService: StorefeedApiService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      let tableCode = params['tableCode'];
      localStorage.setItem("tableCode", tableCode);
      this.load(tableCode)
    });
  }

  load(tableCode : string | null) {

    console.log("log with " + tableCode)

    if( !tableCode) {
      return;
    }

    this.storefeedApiService.getCategories(localStorage.getItem("tableCode")!, null).subscribe({
      next: res => {
        this.categories = res.filter(c => c.numPublicProducts > 0);
      },
      error: err => {

      }
    })

  }


  openProductList(category: CategoryDto) {
    this.selectedCategory.emit(category)
  }

}
